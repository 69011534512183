import React from 'react'
import {Hand} from 'heroicons-react'
import {observer} from 'mobx-react-lite'
import {useRouter} from 'next/router'
import {ButtonAlt, FullModal} from '../../../components'
import {useMessage} from '../../../plugins/use-message'
import {startAuthProcess} from '../../../services/api'
import {auth} from '../../../services/firebase'
import electron from '../../../services/electron'

const Auth: React.FC = observer(() => {
  const router = useRouter()

  const signOut = async () => {
    await auth.signOut()
  }

  useMessage((event) => {
    switch (event?.data?.command) {
      case 'sign-out':
        signOut()
        break
    }
  })

  const onSignIn = () => {
    if (electron) {
      const token = startAuthProcess()
      const url = location.origin + `/auth?client_token=${token}`
      electron.openExternal(url)
    } else {
      router.push('/auth')
    }
  }

  return (
    <FullModal>
      <div>
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-purple-100 text-gray-900 dark:bg-dark-black-800 dark:text-purple-500">
          <Hand />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-near-white">
            Sign-in required
          </h3>
          <div className="mt-2">
            <p className="text-sm leading-5 text-gray-500 dark:text-gray-200">
              No worries. Let&apos;s get you sorted.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-8 text-center">
        <ButtonAlt onClick={onSignIn}>Sign in to your account</ButtonAlt>
      </div>
    </FullModal>
  )
})

export {Auth}
