import React, {MouseEventHandler} from 'react'
import clsx from 'classnames'
import {observer} from 'mobx-react-lite'

interface SearchCreateProps {
  query: string
  selected?: boolean
  onClick?: MouseEventHandler
}

const SearchCreate: React.FC<SearchCreateProps> = observer(
  ({query, selected, onClick}) => {
    if (!query) return null

    return (
      <div
        onClick={onClick}
        className={clsx(
          'group flex flex-col px-4 py-2 text-sm leading-5 hover:bg-pitch-gray-500 text-near-white focus:outline-none transition ease-in-out duration-150 cursor-pointer space-y-1',
          {' bg-pitch-gray-500 text-white': selected},
        )}
      >
        <div className="py-1 flex">
          <div className="truncate font-medium flex-1">
            Create note &apos;{query}&apos;
          </div>
        </div>
      </div>
    )
  },
)

export {SearchCreate}
