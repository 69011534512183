import React, {MouseEventHandler} from 'react'
import Image from 'next/image'
import clsx from 'clsx'
import {usePreferenceStore} from '../../../models/store/context'
import {observer} from 'mobx-react-lite'

interface ThemeItemProps {
  type: 'system' | 'dark' | 'light'
  label: string
  selected?: boolean
  onClick?: MouseEventHandler
}

const ThemeItem: React.FC<ThemeItemProps> = ({
  type,
  label,
  selected = false,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={clsx([
        'rounded-md overflow-hidden cursor-pointer border-2 leading-3',
        selected ? 'border-blue-500' : 'border-gray-200 dark:border-gray-900',
      ])}
    >
      <div className="relative" style={{height: 120}}>
        <Image src={`/app/preferences/color-mode-${type}.svg`} layout="fill" />
      </div>

      <div className="flex items-center px-3 py-2 space-x-2">
        <input type="radio" checked={selected} readOnly />
        <span className="text-sm">{label}</span>
      </div>
    </div>
  )
}

export const ThemePicker: React.FC = observer(() => {
  const preferences = usePreferenceStore()

  return (
    <div className="grid grid-cols-3 gap-4">
      <ThemeItem
        type="system"
        onClick={() => preferences.setTheme('system')}
        selected={preferences.theme === 'system'}
        label="Default to system"
      />

      <ThemeItem
        type="dark"
        onClick={() => preferences.setTheme('dark')}
        selected={preferences.theme === 'dark'}
        label="Dark"
      />

      <ThemeItem
        type="light"
        onClick={() => preferences.setTheme('light')}
        selected={preferences.theme === 'light'}
        label="Light"
      />
    </div>
  )
})
