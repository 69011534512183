import React from 'react'
import {observer} from 'mobx-react-lite'
import {NoteIncomingBacklinksProps} from './types'
import {NoteIncomingBacklinkItem} from './note-incoming-backlink-item'

const NoteIncomingBacklinks: React.FC<NoteIncomingBacklinksProps> = observer(({note}) => {
  const backlinks = note.incomingBacklinks

  if (!backlinks.length) return null

  return (
    <div>
      <h3 className="text-sm font-semibold uppercase tracking-widest leading-5 text-gray-400">
        Incoming backlinks
      </h3>

      <div className="mt-4 space-y-3 empty:hidden">
        {backlinks.map((backlink, i) => (
          <NoteIncomingBacklinkItem key={i} backlink={backlink} />
        ))}
      </div>
    </div>
  )
})

export {NoteIncomingBacklinks}
