import {observer} from 'mobx-react-lite'
import React, {useState} from 'react'
import {ButtonAlt, Select} from '../../../../components'
import {ExportFormat, exportNotes} from '../../../../services/api'
import {Graph} from '../../../models'
interface GraphExportProps {
  graph: Graph
}

export const GraphExport: React.FC<GraphExportProps> = observer(({graph}) => {
  const [selectedFormat, setSelectedFormat] = useState<ExportFormat>('json')

  return (
    <>
      <div className="flex flex-col">
        <h3 className="text-2xl leading-7 font-medium text-gray-900 dark:text-near-white">
          Export
        </h3>

        <p className="mt-4 max-w-2xl text-lg leading-7 text-gray-500 dark:text-near-white">
          Download your data into an unencrypted dump.
        </p>

        <div className="mt-6 sm:mt-5">
          <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-end sm:border-t border-gray-100 dark:border-gray-900 sm:pt-5">
            <div className="block text-gray-700 dark:text-near-white">
              <div className="max-w-32">
                <Select
                  selectedValue={selectedFormat}
                  onChange={(format) => setSelectedFormat(format as ExportFormat)}
                  label="Select format"
                  options={[
                    ['json', 'JSON'],
                    ['csv', 'CSV'],
                  ]}
                />
              </div>
            </div>

            <div className="mt-1 sm:mt-0 sm:col-span-2 flex flex-col space-y-3">
              <ButtonAlt onClick={() => exportNotes(graph, selectedFormat)}>
                Export
              </ButtonAlt>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})
