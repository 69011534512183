import React from 'react'
import {Notes, Preferences, Notifications, ShortcutsModal} from '../screens'
import {Router} from './router'

export const GraphLoader: React.FC = () => {
  return (
    <>
      <Notes />
      <Preferences />
      <Notifications />
      <ShortcutsModal />
      <Router />
    </>
  )
}
