import React from 'react'
import clsx from 'clsx'
import {MouseEventHandler} from 'react'

export const Menu: React.FC = ({children}) => (
  <nav className="flex-none px-4 mt-6 space-y-1">{children}</nav>
)

export interface MenuItemProps {
  selected?: boolean
  onClick?: MouseEventHandler
}

export const MenuItem: React.FC<MenuItemProps> = ({
  children,
  selected = false,
  onClick,
}) => {
  return (
    <a
      onClick={onClick}
      className={clsx([
        'text-gray-500 hover:text-gray-900 hover:bg-gray-200',
        'transition duration-300',
        'group flex items-center space-x-3 px-3 py-3',
        'text-base font-medium rounded-md cursor-pointer',
        'dark:hover:bg-near-black dark:text-near-white',
        selected && 'bg-gray-200 text-gray-800 hover:bg-gray-200 hover:text-gray-900',
        selected && 'dark:bg-near-black dark:text-near-white',
      ])}
    >
      {children}
    </a>
  )
}
