import {setCookie} from 'nookies'

export const setIndexCookie = () => {
  const oneYear = 30 * 24 * 60 * 60 * 360

  // Ensure that requests to the naked
  // domain go straight to the app
  setCookie(null, 'indexApp', '1', {
    maxAge: oneYear,
    path: '/',
  })
}
