import {QuestionMarkCircle} from 'heroicons-react'
import {observer} from 'mobx-react-lite'
import React, {FormEvent, useState} from 'react'
import {FullModal, SubmitButton, Tooltip} from '../../../../components'
import sleep from '../../../../plugins/sleep'
import {Graph} from '../../../models'
import {PasswordInput} from '../../../../components'

interface GraphEditPasswordModalProps {
  graph: Graph
  show: boolean
  onClose: () => void
}

export const GraphEditPasswordModal: React.FC<GraphEditPasswordModalProps> = observer(
  ({graph, show, onClose}) => {
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [passwordMatchError, setPasswordMatchError] = useState(false)
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('')

    const changePassword = async (event: FormEvent) => {
      event.preventDefault()

      if (loading) return

      setLoading(true)
      await sleep(100)

      const validPassword = graph.encrypted ? await graph.isValidPassword(password) : true
      const newPasswordConfirmed = newPassword && newPassword === newPasswordConfirm

      setPasswordError(!validPassword)
      setPasswordMatchError(!newPasswordConfirmed)

      if (!validPassword || !newPasswordConfirmed) return setLoading(false)

      await graph.changePassword(newPassword)

      onClose()
    }

    return (
      <FullModal show={show} onClose={onClose}>
        <div className="mt-3 sm:mt-5 max-w-sm">
          <div className="text-center">
            <h3 className="text-2xl leading-6 font-medium text-gray-800 dark:text-near-white">
              Encryption password change
            </h3>

            <div className="mt-3">
              <p className="text-sm leading-5 text-gray-500 dark:text-near-white">
                Passwords can{' '}
                <em>
                  <strong>never be recovered</strong>
                </em>
                . We suggest exporting your notes before proceeding.
              </p>
            </div>
          </div>

          <div className="mt-5">
            <form onSubmit={changePassword}>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-near-white">
                    Graph
                  </label>

                  <div className="mt-2 flex-1 py-2 px-3 rounded-md block w-full min-w-0 sm:text-sm sm:leading-5 bg-gray-200">
                    {graph.name}
                  </div>
                </div>

                {graph.encrypted && (
                  <>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-near-white">
                        Existing password
                      </label>

                      <div className="mt-2">
                        <PasswordInput
                          value={password}
                          onChange={setPassword}
                          error={passwordError}
                        />
                      </div>

                      {passwordError && (
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                          Incorrect password.
                        </p>
                      )}
                    </div>
                  </>
                )}

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-near-white">
                    New password
                  </label>

                  <div className="mt-2 relative">
                    <PasswordInput
                      newPassword={true}
                      value={newPassword}
                      onChange={setNewPassword}
                    />

                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-help text-gray-400">
                      <Tooltip label="Passwords must be at least eight characters. Passwords don't leave your machine and can NEVER be recovered.">
                        <QuestionMarkCircle />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-near-white">
                    Confirm new password
                  </label>

                  <div className="mt-2">
                    <PasswordInput
                      newPassword={true}
                      value={newPasswordConfirm}
                      onChange={setNewPasswordConfirm}
                    />
                  </div>

                  {passwordMatchError && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      Password confirmation did not match.
                    </p>
                  )}
                </div>
              </div>

              <div className="mt-7">
                <SubmitButton
                  loading={loading}
                  label="Change password"
                  loadingLabel="Changing password... DO NOT CLOSE"
                />
              </div>
            </form>
          </div>
        </div>
      </FullModal>
    )
  },
)
