import {Note} from 'app/models/note/note'
import {useNoteStore} from 'app/models/store/context'
import {ErrorBoundaryFallback} from 'components'
import {BacklinkNodeData} from 'components/rich-text/plugins/backlink'
import {TagNodeData} from 'components/rich-text/plugins/tags'
import {RichText} from 'components/rich-text/rich-text'
import {observer} from 'mobx-react-lite'
import React, {useEffect, useMemo} from 'react'
import {identify} from 'services/analytics'

interface Props {
  notes: Note[]
  note: Note
  isLastNote: boolean
  availableBacklinks: BacklinkNodeData[]
}

const NoteEdit: React.FC<Props> = observer(
  ({notes, note, isLastNote, availableBacklinks}) => {
    const noteStore = useNoteStore()
    const selected = note === noteStore.selectedNote
    const selectingEdge = noteStore.noteSelectingEdge
    const graph = note.graph

    const noteIndex = useMemo(() => {
      return notes.findIndex((lookupNote) => lookupNote === note)
    }, [notes, note])

    const availableBacklinksForNote = useMemo(
      () => availableBacklinks.filter((bl) => bl.note != note),
      [note],
    )

    const onAddBacklink = (backlink: BacklinkNodeData) => {
      identify({backlink_created: true})
      return noteStore.onAddBacklink(backlink)
    }

    const onAddTag = (tag: TagNodeData) => {
      identify({tag_created: true})
      return graph?.onAddTag(tag) || tag
    }

    const onNavigatePrevious = () => {
      if (noteIndex > 0) {
        const previousIndex = noteIndex - 1
        noteStore.noteSelectingEdge = 'bottom'
        noteStore.setSelectedNote(notes[previousIndex])
      }
    }

    const onNavigateNext = () => {
      if (noteIndex < notes.length - 1) {
        const nextIndex = noteIndex + 1
        noteStore.noteSelectingEdge = 'top'
        noteStore.setSelectedNote(notes[nextIndex])
      }
    }

    useEffect(() => {
      return () => {
        noteStore.noteSelectingEdge = 'default'
      }
    }, [])

    // Note has been deleted
    if (!graph) return null

    return (
      <div
        style={{minHeight: isLastNote ? '80vh' : 'inherit'}}
        className="flex-grow flex-shrink-0 flex flex-col border-b border-gray-100 dark:border-dark-black-800"
      >
        <div className="max-w-screen-md flex-grow flex-shrink-0 flex flex-col">
          <ErrorBoundaryFallback getHtml={() => note.contentHtml}>
            <RichText
              key={note.key}
              availableBacklinks={availableBacklinksForNote}
              availableMergeNotes={[]}
              availableTags={graph.availableTags}
              subject={note.subject}
              content={note.contentSnapshot}
              onChangeContent={(value) => note.updateContent(value, {debounce: true})}
              onAddBacklink={onAddBacklink}
              onAddTag={onAddTag}
              onFocus={() => noteStore.setSelectedNote(note)}
              onNavigatePrevious={onNavigatePrevious}
              onNavigateNext={onNavigateNext}
              selected={selected}
              selectingEdge={selectingEdge}
            />
          </ErrorBoundaryFallback>
        </div>
      </div>
    )
  },
)

export {NoteEdit}
