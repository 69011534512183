import {IStyle} from '@uifabric/styling'
import {
  SubjectMergeSelectStyleProps,
  SubjectMergeSelectStyles,
} from './subject-merge.types'

const classNames = {
  root: 'slate-SubjectMergeSelect',
  subjectMergeItem: 'slate-SubjectMergeSelect-subjectMergeItem',
  subjectMergeItemSelected: 'slate-SubjectMergeSelect-subjectMergeItemSelected',
}

export const getSubjectMergeSelectStyles = ({
  className,
}: SubjectMergeSelectStyleProps = {}): SubjectMergeSelectStyles => {
  const subjectMergeItem: IStyle = [
    classNames.subjectMergeItem +
      ' block px-4 py-2 text-sm leading-5 text-gray-700 cursor-pointer' +
      ' hover:bg-gray-100 hover:text-gray-900' +
      ' dark:bg-dark-black-800 dark:text-near-white' +
      ' transition ease-in-out duration-150',
    {},
  ]

  const subjectMergeItemSelected: IStyle = [
    classNames.subjectMergeItemSelected +
      ' block px-4 py-2 text-sm leading-5 text-gray-700 cursor-pointer' +
      ' bg-gray-100 text-gray-900' +
      ' dark:bg-gray-100 dark:text-gray-900' +
      ' transition ease-in-out duration-150',
    {},
  ]

  return {
    root: [
      classNames.root,
      {
        top: '-9999px',
        left: '-9999px',
        position: 'absolute',
        zIndex: 1,
        minWidth: '200px',
      },
      className + ' rounded-md shadow-lg overflow-hidden bg-white dark:bg-dark-black-800',
    ],
    subjectMergeItem,
    subjectMergeItemSelected,
  }
}
