import React from 'react'
import {ButtonAlt} from '../../../../components'
import {Credential} from '../../../models/credential/credential'

interface CredentialProps {
  credential: Credential
}

export const CredentialItem: React.FC<CredentialProps> = ({credential}) => {
  return (
    <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:pt-5">
      <label className="block text-sm font-medium leading-5 text-gray-700 dark:text-near-white sm:mt-px sm:pt-2">
        <span className="border border-dashed px-4 py-2 rounded-full border-purple-300">
          {credential.email}
        </span>
      </label>

      <div className="text-sm flex items-center center leading-5">
        {credential.contactsCount !== undefined
          ? `${credential.contactsCount} contacts synced`
          : 'Syncing contacts...'}
      </div>

      <div>
        <ButtonAlt small onClick={() => confirm('Are you sure') && credential.revoke()}>
          Revoke access
        </ButtonAlt>
      </div>
    </div>
  )
}
