import React, {useEffect, useRef} from 'react'
import {observer} from 'mobx-react-lite'
import {useNoteStore} from '../../../models/store/context'
import {NoteListItem} from './note-list-item'
import {Virtuoso, VirtuosoHandle} from 'react-virtuoso'
import {useDimensions} from '../../../../plugins/use-dimensions'

const PinnedNotes: React.FC = observer(() => {
  const noteStore = useNoteStore()

  const {pinnnedNotes: notes, selectedNote, view} = noteStore

  const virtuoso = useRef<VirtuosoHandle | null>(null)
  const editView = view === 'edit'

  const [{height}, ref] = useDimensions()

  useEffect(() => {
    if (!selectedNote) return

    const selectedIndex = notes.indexOf(selectedNote)

    if (editView && selectedIndex > -1) {
      virtuoso.current?.scrollToIndex({
        index: selectedIndex,
        behavior: 'smooth',
      })
    }
  }, [selectedNote])

  const Row = (index: number) => {
    const note = notes[index]

    return (
      <div className="pb-1">
        <NoteListItem
          key={note.id}
          note={note}
          selected={editView && note === selectedNote}
          onClick={() => noteStore.editNote(note)}
        />
      </div>
    )
  }

  if (!notes.length) return null

  return (
    <div className="flex-1 flex flex-col mx-4 pt-2">
      <h3 className="text-sm px-4 tracking-widest font-semibold leading-5 text-gray-400 uppercase">
        Pinned notes
      </h3>

      <div ref={ref as any} className="flex-1 mt-2">
        <Virtuoso
          ref={virtuoso}
          style={{width: '100%', height: height || 100}}
          totalCount={notes.length}
          itemContent={Row}
        />
      </div>
    </div>
  )
})

export {PinnedNotes}
