import React from 'react'
import {observer} from 'mobx-react-lite'
import {Note} from '../../../../models'
import {Person} from './person'
import {Company} from './company'

interface NoteEnrichmentsProps {
  note: Note
}

export const NoteEnrichment: React.FC<NoteEnrichmentsProps> = observer(({note}) => {
  const enrichment = note.enrichment || {}
  const {person, company} = enrichment

  const personName = person?.name?.fullName
  const companyName = company?.name

  // Need enough info to show something
  if (!personName && !companyName) return null

  return (
    <div className="bg-white dark:bg-dark-black-900 rounded-md px-4 py-4 pb-3 shadow-sm">
      {person && <Person person={person} />}
      {person && company && (
        <div className="my-4 mb-4 border-t border-gray-50 dark:border-dark-black-800" />
      )}
      {company && <Company company={company} />}
    </div>
  )
})
