import {action, computed, observable} from 'mobx'
import {Model, model, prop} from 'mobx-keystone'
import {
  ContactStore,
  CredentialStore,
  GraphStore,
  NotificationStore,
  PreferenceStore,
  PreferenceView,
  UserStore,
  BacklinkNodeData,
  Note,
  MeetingStore,
} from '../'
import {LoadingState, RootViewTypes} from '../../core/types'

@model('root')
export class RootStore extends Model({
  notificationStore: prop<NotificationStore>(() => new NotificationStore({})),
  credentialStore: prop<CredentialStore>(() => new CredentialStore({})),
  preferenceStore: prop<PreferenceStore>(() => new PreferenceStore({})),
  graphStore: prop<GraphStore>(() => new GraphStore({})),
  userStore: prop<UserStore>(() => new UserStore({})),
  contactStore: prop<ContactStore>(() => new ContactStore({})),
  meetingStore: prop<MeetingStore>(() => new MeetingStore({})),
}) {
  @observable
  view: RootViewTypes = 'notes'

  get noteStore() {
    return this.graphStore.currentGraph?.noteStore
  }

  get assertNoteStore() {
    const noteStore = this.noteStore
    if (!noteStore) throw new Error('noteStore blank')
    return noteStore
  }

  get bookStore() {
    return this.graphStore.currentGraph?.bookStore
  }

  get currentGraph() {
    return this.graphStore.currentGraph
  }

  get assertCurrentGraph() {
    const graph = this.currentGraph
    if (!graph) throw new Error('graph blank')
    return graph
  }

  get currentUser() {
    return this.userStore.currentUser
  }

  get assertCurrentUser() {
    const user = this.currentUser
    if (!user) throw new Error('user blank')
    return user
  }

  get uid() {
    return this.userStore.currentUser?.id
  }

  get currentUserIsGraphOwner() {
    return this.currentGraph?.isOwner
  }

  @computed
  get needsGraphSetup() {
    const user = this.currentUser

    if (!user) return false
    if (!user.loaded) return false

    // If we've loaded graphIds, but they are empty
    return !user.graphIds.length
  }

  get needsAuth() {
    return this.userStore.needsAuth
  }

  @computed
  get needsUngating(): boolean {
    const user = this.currentUser

    if (!user) return false
    if (!user.loaded) return false

    return user.gatedAccess === false
  }

  @computed
  get availableBacklinks(): BacklinkNodeData[] {
    const {availableBacklinks: notes} = this.assertNoteStore
    const {availableBacklinks: contacts} = this.contactStore

    // Remove contacts that have the same name as a note
    const notesValues = notes.map((n) => n.value)
    const filteredContacts = contacts.filter((bl) => !notesValues.includes(bl.value))

    return notes.concat(filteredContacts)
  }

  availableBacklinksForNote(note: Note): BacklinkNodeData[] {
    return this.availableBacklinks.filter((bl) => bl.note != note)
  }

  @action
  setView(view: RootViewTypes) {
    this.view = view
  }

  @action
  setDefaultView() {
    this.view = 'notes'
  }

  reset() {
    this.userStore.reset()
    this.noteStore?.reset()
  }

  setupDefaults({
    graphId,
    noteId,
    preferencesView,
  }: {
    graphId?: string
    noteId?: string
    preferencesView?: string
  }) {
    if (graphId) {
      this.graphStore.ensureGraphId(graphId, true)
      this.graphStore.setCurrentGraphId(graphId)
    }

    if (noteId) {
      this.noteStore?.setSelectedNoteId(noteId)
    }

    if (preferencesView) {
      this.preferenceStore.setView(preferencesView as PreferenceView)
      this.setView('preferences')
    }
  }

  @computed
  get loadingState(): LoadingState {
    const graph = this.currentGraph

    const loadingStateValues: [boolean, LoadingState][] = [
      [this.needsAuth, LoadingState.Auth],
      [this.needsGraphSetup, LoadingState.GraphSetup],
      [!!graph?.needsEncryptionSetup, LoadingState.GraphEncryptionSetup],
      [!!graph?.noteStore, LoadingState.NoteStore],
    ]

    for (const [value, loadingState] of loadingStateValues) {
      if (value) return loadingState
    }

    return LoadingState.Pending
  }
}
