import React from 'react'
import {observer} from 'mobx-react-lite'
import {Meeting, Note} from '../../../../models'
import {useNoteStore} from '../../../../models/store/context'
import {format} from 'date-fns'
import clsx from 'clsx'
import {Plus} from 'heroicons-react'

interface Props {
  note: Note
  meeting: Meeting
}

export const MeetingItem: React.FC<Props> = observer(({note, meeting}) => {
  const noteStore = useNoteStore()

  return (
    <>
      <div
        title={`${meeting.name} - ${meeting.attendees.map((a) => a.email).join(' / ')}`}
        onClick={() => noteStore.addMeeting({note, meeting})}
        className="px-4 py-3 flex text-sm items-center space-x-2 cursor-pointer"
      >
        <div className="flex-none w-16">
          {meeting.startAt ? format(meeting.startAt, 'p') : ''}
        </div>

        <div className="text-purple-550 flex-1 truncate">{meeting.name}</div>

        <div className="flex items-center">
          <button
            className={clsx(
              'rounded-md outline-none',
              'text-purple-550 text-xs uppercase font-medium',
              'focus:outline-none active:ring-2 active:ring-purple-600 active:ring-opacity-50',
              'transition-all duration-300',
            )}
          >
            <Plus size={20} />
          </button>
        </div>
      </div>
    </>
  )
})
