import React, {useEffect, useState} from 'react'
import {Transition} from '@headlessui/react'
import {ContextMenuProps, Coords} from './types'
import {PortalBody} from '..'

const ContextMenu: React.FC<ContextMenuProps> = ({
  children,
  parentRef,
  className,
  enabled = true,
}) => {
  const [coords, setCoords] = useState<Coords | null>(null)

  useEffect(() => {
    const parent = parentRef.current

    if (!parent) {
      return
    }

    const onClick = () => {
      setCoords(null)
    }

    const onContextMenu = (event: MouseEvent) => {
      if (parent.contains(event.target)) {
        event.preventDefault()
        setCoords({x: event.clientX, y: event.clientY})
      } else {
        setCoords(null)
      }
    }

    window.addEventListener('click', onClick)
    window.addEventListener('contextmenu', onContextMenu)

    return function cleanup() {
      window.removeEventListener('contextmenu', onContextMenu)
      window.removeEventListener('click', onClick)
    }
  }, [])

  if (!coords || !enabled) return null

  return (
    <PortalBody>
      <Transition
        appear={true}
        show={true}
        enter="transition-all transform origin-bottom duration-75"
        enterFrom="opacity-0 scale-75"
        enterTo="opacity-100 scale-100"
        leave="transition-all transform origin-bottom duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {(ref: React.Ref<HTMLDivElement> | undefined) => (
          <div
            ref={ref}
            className={className}
            style={{
              position: 'absolute',
              top: coords!.y,
              left: coords!.x,
            }}
          >
            {children}
          </div>
        )}
      </Transition>
    </PortalBody>
  )
}

export {ContextMenu}
