import React from 'react'
import {observer} from 'mobx-react-lite'
import {SplitPane} from '../../../components'
import {PreferencesSidebar} from './preferences-sidebar/preferences-sidebar'
import {usePreferenceStore, useRootStore} from '../../models/store/context'
import {useMessage} from '../../../plugins/use-message'
import {useHotkeys} from 'react-hotkeys-hook'
import {
  PreferencesContacts,
  PreferencesGraph,
  PreferencesProfile,
} from './preferences-dynamic'
import {PreferencesBilling} from './preferences-billing/preferences-billing'

const Preferences: React.FC = observer(() => {
  const rootStore = useRootStore()

  const {view} = usePreferenceStore()

  const open = () => rootStore.setView('preferences')
  const close = () => rootStore.setDefaultView()

  useMessage((event) => {
    switch (event?.data?.command) {
      case 'open-preferences':
        open()
        break
    }
  })

  useHotkeys('escape', () => close())

  // Chrome PWA API
  useHotkeys(
    'command+,,control+,',
    (event) => {
      event.preventDefault()
      open()
    },
    {
      filter: () => true,
    },
  )

  if (rootStore.view != 'preferences') return null

  const renderView = () => {
    switch (view) {
      case 'profile':
        return <PreferencesProfile />
      case 'credentials':
        return <PreferencesContacts />
      case 'billing':
        return <PreferencesBilling />
      case 'graph':
        return <PreferencesGraph />
    }
  }

  return (
    <div className="dark:bg-dark-black-900 dark:text-near-white">
      <SplitPane direction="left" name="pref-lhc">
        <PreferencesSidebar />

        <main className="flex-1 flex flex-col overflow-auto">{renderView()}</main>
      </SplitPane>
    </div>
  )
})

export {Preferences}
