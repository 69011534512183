import React from 'react'
import {PlaceholderImage} from '../../../../components'
import {observer} from 'mobx-react-lite'
import {User} from '../../../models'

interface Props {
  user: User
}

export const UserPhoto: React.FC<Props> = observer(({user}) => {
  return (
    <div
      title="Change your profile picture"
      className="h-12 w-12 rounded-full overflow-hidden bg-gray-100 relative cursor-pointer"
    >
      <PlaceholderImage src={user.photoUrl}>
        <svg
          className="h-full w-full text-gray-300"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
      </PlaceholderImage>

      <input
        type="file"
        className="absolute inset-0 opacity-0 cursor-pointer w-full z-10"
        accept="image/png,image/jpeg,image/jpg"
        onChange={(event) => user.updatePhoto(event.currentTarget.files![0])}
      />
    </div>
  )
})
