import React from 'react'
import {NoteSuggestedIncomingBacklinksItemProps} from './types'
import {ArrowLeftOutline, Ban, Check} from 'heroicons-react'
import {useNoteStore} from '../../../../models/store/context'
import {observer} from 'mobx-react-lite'

export const NoteSuggestedIncomingBacklinksItem: React.FC<NoteSuggestedIncomingBacklinksItemProps> = observer(
  ({onClickAdd, onClickIgnore, backlink}) => {
    const noteStore = useNoteStore()
    const fromNote = noteStore.findById(backlink.fromNoteId)

    return (
      <div className="rounded-md bg-white dark:bg-dark-black-900 shadow-sm px-4 py-4 flex flex-col">
        <div className="flex items-center">
          <div
            className="flex flex-1 space-x-2 items-center overflow-hidden"
            title="Incoming backlink"
          >
            <ArrowLeftOutline size={12} />
            <div
              className="text-sm leading-5 text-purple-550 flex-1 truncate cursor-pointer"
              onClick={() => noteStore.setSelectedNote(fromNote || null)}
            >
              {fromNote?.subject}
            </div>
          </div>

          <div className="flex-none flex space-x-2">
            <button
              onClick={onClickIgnore}
              title="Ignore suggestion"
              className="text-gray-300 cursor-pointer hover:shadow-outline-purple transition ease-in-out duration-300 rounded-md"
            >
              <Ban size={20} />
            </button>

            <button
              onClick={onClickAdd}
              title="Add backlink"
              className=" text-green-400 cursor-pointer hover:shadow-outline-purple transition ease-in-out duration-300 rounded-md"
            >
              <Check size={20} />
            </button>
          </div>
        </div>

        <div
          className="overflow-hidden prose prose-sm text-xs text-gray-900 dark:text-near-white backlink-html-context py-1 empty:hidden"
          dangerouslySetInnerHTML={{__html: backlink.contextHtml || ''}}
        />
      </div>
    )
  },
)
