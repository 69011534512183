import {XOutline} from 'heroicons-react'
import React from 'react'
import {Notification} from '../../models'

interface NotificationItemProps {
  notification: Notification
}

export const NotificationItem: React.FC<NotificationItemProps> = ({notification}) => (
  <div className="min-w-md w-full bg-white dark:bg-gray-900 shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5">
    <div className="w-0 flex-1 p-4">
      <div className="flex items-start">
        <div className="w-0 pl-1 flex-1 flex items-center space-x-4">
          <p className="text-sm font-medium text-gray-900 dark:text-near-white">
            {notification.subject}
          </p>
          <p className="text-sm text-gray-500 dark:text-near-white">
            {notification.description}
          </p>
        </div>
      </div>
    </div>

    {!notification.closeIn && (
      <>
        <div className="flex border-l border-gray-200">
          <button
            onClick={() => notification.close()}
            className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            <XOutline size={15} />
          </button>
        </div>
      </>
    )}
  </div>
)
