import {observer} from 'mobx-react-lite'
import React from 'react'
import {LoadingIndicator} from '../../components'
import {useRootStore} from '../models/store/context'
import {Auth, Gated, GraphSetup, GraphSetupEncryption} from '../screens'
import {GraphLoader} from './graph-loader'
import {LoadingState} from './types'

export const Loader: React.FC = observer(() => {
  const rootStore = useRootStore()

  console.log('LoadingState:', rootStore.loadingState)

  switch (rootStore.loadingState) {
    case LoadingState.Auth:
      return <Auth />
    case LoadingState.Gated:
      return <Gated />
    case LoadingState.GraphSetup:
      return <GraphSetup />
    case LoadingState.GraphEncryptionSetup:
      return <GraphSetupEncryption />
    case LoadingState.NoteStore:
      return <GraphLoader />
    case LoadingState.Pending:
      return <LoadingIndicator />
    default:
      return null
  }
})
