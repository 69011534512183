import React from 'react'
import {observer} from 'mobx-react-lite'
import {usePreferenceStore} from '../../../models/store/context'
import {GraphExport} from './graph-export'
import {GraphEdit} from './graph-edit'
import {GraphImport} from './graph-import'

export const PreferencesGraph: React.FC = observer(() => {
  const {selectedGraph} = usePreferenceStore()

  const graph = selectedGraph?.current

  if (!graph) {
    throw new Error('blank graph')
  }

  return (
    <>
      <div className="px-10 py-10 space-y-12">
        <GraphEdit graph={graph} />
        <GraphImport graph={graph} />
        <GraphExport graph={graph} />
      </div>
    </>
  )
})
