import React from 'react'
import {observer} from 'mobx-react-lite'
import {Note} from '../../../../models'
import {useContactStore, useNoteStore} from '../../../../models/store/context'
import {Ban, Check} from 'heroicons-react'

interface NoteSuggestContactProps {
  note: Note
}

export const NoteSuggestContact: React.FC<NoteSuggestContactProps> = observer(
  ({note}) => {
    const contactStore = useContactStore()
    const noteStore = useNoteStore()

    // Hide if contact already associated
    if (note.enrichment || note.contentEmails.length || note.contentPhoneNumbers.length)
      return null

    const contact = contactStore.findByName(note.subject)

    // We can't find an associated contact with the same name
    if (!contact) return null

    // We have ignored this contact already
    if (note.ignoredContactNames.includes(contact.name)) return null

    return (
      <div>
        <h3 className="text-base font-medium leading-5 text-gray-500">
          Suggested contact
        </h3>

        <div className="flex mt-4 space-x-4 bg-white dark:bg-dark-black-900 rounded-md px-4 py-4 pb-3 shadow-sm">
          <div className="rounded-md bg-gray-300 overflow-hidden h-16 w-16 flex-none">
            {contact.photoUrl && (
              <div
                className="bg-contain bg-center bg-no-repeat inline-block h-full w-full"
                style={{backgroundImage: `url(${contact.photoUrl})`}}
              />
            )}
          </div>

          <div className="flex-1 flex flex-col space-y-2 overflow-hidden">
            <h3 className="text-purple-550 leading-5 text-lg">{contact.name}</h3>

            <div className="space-y-1 text-gray-700 text-sm">
              <div className="truncate">{contact.emailValues[0]}</div>

              <div className="truncate">{contact.phoneNumberValues[0]}</div>
            </div>
          </div>

          <div className="flex-none flex space-x-2 items-start">
            <button
              onClick={() => note.ignoreSuggestedContact(contact)}
              title="Ignore suggestion"
              className="text-gray-300 block cursor-pointer focus:outline-none focus:shadow-outline-purple transition ease-in-out duration-300 rounded-md"
            >
              <Ban size={20} />
            </button>

            <button
              onClick={() => note.addSuggestedContact(contact)}
              title="Add contact"
              className=" text-green-400 block cursor-pointer focus:outline-none focus:shadow-outline-purple transition ease-in-out duration-300 rounded-md"
            >
              <Check size={20} />
            </button>
          </div>
        </div>
      </div>
    )
  },
)
