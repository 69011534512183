import {observer} from 'mobx-react-lite'
import React, {useState} from 'react'
import {PseudoButton} from '../../../../components'
import {importRoamNotes} from '../../../../services/api'
import {Graph} from '../../../models'
import {useNotificationStore} from '../../../models/store/context'
import {RoamImportConfirm} from './roam-import-confirm'

interface GraphImportProps {
  graph: Graph
}

export const GraphImport: React.FC<GraphImportProps> = observer(({graph}) => {
  const [roamFile, setRoamFile] = useState<File | null>(null)
  const [roamImporting, setImporting] = useState(false)
  const notifications = useNotificationStore()

  const confirmRoamImport = async () => {
    if (!roamFile) return

    setImporting(true)
    await importRoamNotes(graph, roamFile)
    setImporting(false)
    setRoamFile(null)
    notifications.create({
      subject: 'Imported',
      description: 'Notes successfully imported',
    })
  }

  return (
    <>
      <RoamImportConfirm
        graph={graph}
        show={!!roamFile}
        importing={roamImporting}
        onConfirm={() => confirmRoamImport()}
        onClose={() => setRoamFile(null)}
      />

      <div className="flex flex-col">
        <h3 className="text-2xl leading-7 font-medium text-gray-900 dark:text-near-white">
          Import
        </h3>

        <p className="mt-4 max-w-2xl text-lg leading-7 text-gray-500 dark:text-near-white">
          Import your existing notes from third party providers.
        </p>

        <div className="mt-6 sm:mt-5">
          <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t border-gray-100 dark:border-gray-900 sm:pt-5">
            <label className="block text-sm font-medium leading-5 text-gray-700 dark:text-near-white sm:mt-px sm:pt-2">
              Roam Research
            </label>

            <div className="mt-1 sm:mt-0 sm:col-span-2 flex flex-col space-y-3">
              <label>
                <PseudoButton>Select Roam JSON export</PseudoButton>

                <input
                  type="file"
                  className="hidden"
                  accept="text/json,application/json"
                  onChange={(event) => setRoamFile(event.currentTarget.files![0])}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})
