import {isRootStore, registerRootStore, setGlobalConfig} from 'mobx-keystone'
import {generateId} from '../../../plugins/generate-id'
import {persist} from '../../../plugins/keystone-persist'
import {RootStore} from './root-store'

interface RootStoreProps {
  graphId?: string
  noteId?: string
  preferencesView?: string
}

setGlobalConfig({
  modelIdGenerator: generateId,
})

let store: RootStore | null = null

const migration = 14

export async function setupRootStore({graphId, noteId, preferencesView}: RootStoreProps) {
  if (!store) {
    store = new RootStore({})
  }

  await persist('preferences-store', store.preferenceStore, {
    blacklist: ['view'],
    migration,
  })

  await persist('user-store', store.userStore, {
    whitelist: ['currentUser'],
    migration,
  })

  await persist('graph-store', store.graphStore, {
    whitelist: ['graphs'],
    migration,
  })

  await persist('meeting-store', store.meetingStore, {
    whitelist: ['dayMeetings'],
    migration,
  })

  store.setupDefaults({graphId, noteId, preferencesView})

  // It's important this happens last, otherwise
  // the graphs get attached twice
  if (!isRootStore(store)) registerRootStore(store)

  return store
}
