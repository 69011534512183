import React, {MouseEventHandler} from 'react'
import clsx from 'classnames'
import {observer} from 'mobx-react-lite'
import {SearchResult} from './types'

interface SearchResultItemProps {
  result: SearchResult
  selected?: boolean
  onClick?: MouseEventHandler
}

const SearchResultItem: React.FC<SearchResultItemProps> = observer(
  ({result, selected, onClick}) => {
    return (
      <div
        style={{height: 64}}
        onClick={onClick}
        className={clsx(
          'group flex flex-col justify-center px-5 py-3 text-sm leading-5 cursor-pointer space-y-1',
          'hover:bg-pitch-gray-500 text-near-white focus:outline-none',
          'transition ease-in-out duration-150',
          {' bg-pitch-gray-500 text-white': selected},
        )}
      >
        <div className="flex space-x-2">
          <div className="truncate font-medium flex-1" title={result.title}>
            {result.title}
          </div>
        </div>

        <div className="flex space-x-2">
          {result.updated && (
            <>
              <div className="flex-none text-xs" title={result.updated}>
                {result.updated}
              </div>
            </>
          )}

          {result.snippet && (
            <>
              <div className="truncate text-xs flex-1" title={result.snippet}>
                {result.snippet}
              </div>
            </>
          )}
        </div>
      </div>
    )
  },
)

export {SearchResultItem}
