import React from 'react'
import {observer} from 'mobx-react-lite'
import {NoteOutgoingBacklinksProps} from './types'
import {NoteOutgoingBacklinkItem} from './note-outgoing-backlink-item'
import {NoteTagItem} from './note-tag-item'

const NoteOutgoingBacklinks: React.FC<NoteOutgoingBacklinksProps> = observer(({note}) => {
  const {outgoingBacklinks, tags} = note

  if (!outgoingBacklinks.length && !tags.length) return null

  return (
    <div>
      <h3 className="text-sm font-semibold uppercase tracking-widest leading-5 text-gray-400">
        Outgoing backlinks &amp; tags
      </h3>

      <div className="mt-4 bg-white dark:bg-dark-black-900 rounded-md shadow-sm">
        <div className="divide-y divide-bluegray-100 dark:divide-gray-900">
          {tags.map((tag, i) => (
            <NoteTagItem key={i} tag={tag} />
          ))}

          {outgoingBacklinks.map((backlink, i) => (
            <NoteOutgoingBacklinkItem key={i} backlink={backlink} />
          ))}
        </div>
      </div>
    </div>
  )
})

export {NoteOutgoingBacklinks}
