import React, {FormEvent, useState} from 'react'
import {LockClosed, QuestionMarkCircle} from 'heroicons-react'
import {observer} from 'mobx-react-lite'
import {Modal, PasswordInput, SubmitButton, Tooltip} from '../../../components'
import {useRootStore} from '../../models/store/context'
import sleep from '../../../plugins/sleep'

export const GraphSetupEncryption: React.FC = observer(() => {
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const {assertCurrentGraph: graph} = useRootStore()

  const setupAccount = async (event: FormEvent) => {
    event.preventDefault()

    if (loading) return
    setLoading(true)

    // To force a re-render and get
    // the loading spinner working
    await sleep(100)

    const validPassword = await graph.isValidPassword(password)

    setPasswordError(!validPassword)

    if (validPassword) {
      await graph.setPassword(password)
    }

    setLoading(false)
  }

  return (
    <Modal show={true}>
      <div className="mt-3 sm:mt-5">
        <div className="text-center">
          <h3 className="text-2xl leading-6 font-medium text-gray-800 dark:text-near-white">
            {graph.name}
          </h3>

          <div className="mt-3">
            <p className="text-sm leading-5 text-gray-500">
              Enter the password you used
              <br /> to encrypt your notes.
            </p>
          </div>
        </div>

        <div className="mt-5">
          <form onSubmit={setupAccount}>
            <div className="space-y-3">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-near-white">
                  Encryption password
                </label>

                <div className="mt-2 relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-yellow-400">
                    <LockClosed />
                  </div>

                  <PasswordInput
                    value={password}
                    onChange={setPassword}
                    error={passwordError}
                  />

                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-help text-gray-400">
                    <Tooltip label="Passwords must be at least eight characters. Passwords don't leave your machine and can NEVER be recovered.">
                      <QuestionMarkCircle />
                    </Tooltip>
                  </div>
                </div>

                {passwordError && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    Password did not match.
                  </p>
                )}
              </div>
            </div>

            <div className="mt-5">
              <SubmitButton
                loading={loading}
                label="Sign in"
                loadingLabel="Signing-in..."
              />
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
})
