import React from 'react'
import {observer} from 'mobx-react-lite'
import {useNoteStore} from '../../../models/store/context'

const SearchNotes: React.FC = observer(() => {
  const noteStore = useNoteStore()

  return (
    <div className="flex-none px-4">
      <div className="relative rounded-md shadow-sm text-gray-400">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <svg className="mr-3 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div
          className=" bg-white dark:bg-dark-black-900 block w-full pl-9 text-base font-medium leading-5 outline-none py-3 px-3 rounded-md cursor-text transition ease-in-out duration-150"
          onClick={() => noteStore.openQuickSearch()}
        >
          Search
        </div>
      </div>
    </div>
  )
})

export {SearchNotes}
