import clsx from 'clsx'
import {ArrowDown} from 'heroicons-react'
import React, {MouseEventHandler} from 'react'

interface TodaysNoteButtonProps {
  onClick: MouseEventHandler
}

export const TodaysNoteButton: React.FC<TodaysNoteButtonProps> = ({onClick}) => (
  <button
    onClick={onClick}
    type="button"
    className={clsx([
      'inline-flex items-center px-4 py-2 shadow-sm rounded-full',
      'bg-white dark:bg-dark-black-900',
      'leading-4 font-medium text-sm text-gray-700 dark:text-near-white',
      'hover:text-purple-600 focus:outline-none focus:shadow-outline-purple',
      'transition ease-in-out duration-300',
      'cursor-pointer space-x-1.5',
    ])}
  >
    <ArrowDown size={15} />
    <span>Today&apos;s note</span>
  </button>
)
