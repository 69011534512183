import {PlusOutline} from 'heroicons-react'
import {observer} from 'mobx-react-lite'
import React from 'react'
import {ButtonAlt, openWindow} from '../../../../components'
import {identify} from '../../../../services/analytics'
import {getAuthGoogleCredentialsUrl} from '../../../../services/api'
import {useCredentialStore} from '../../../models/store/context'
import {CredentialItem} from './credential-item'

export const PreferencesCredentials: React.FC = observer(() => {
  const {credentials} = useCredentialStore()

  const authGoogleCredentials = async () => {
    const url = await getAuthGoogleCredentialsUrl()
    openWindow(url)
    identify({credential_auth: true})
  }

  return (
    <div className="px-10 pt-10 flex flex-col">
      <div className="flex">
        <div className="flex-grow">
          <h3 className="text-2xl leading-7 font-medium text-gray-900 dark:text-near-white">
            Connected Google accounts
          </h3>

          <p className="mt-4 max-w-2xl text-lg leading-5 text-gray-500 dark:text-near-white">
            Add more to populate your contacts and calendar events.
          </p>
        </div>

        <div>
          <ButtonAlt onClick={() => authGoogleCredentials()}>
            <PlusOutline size={20} />
            <span>Add Google account</span>
          </ButtonAlt>
        </div>
      </div>

      <div className="mt-6 sm:mt-5">
        {credentials.map((credential) => (
          <CredentialItem key={credential.id} credential={credential} />
        ))}
      </div>
    </div>
  )
})
