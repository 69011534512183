import React, {FormEvent, useState} from 'react'
import {QuestionMarkCircle} from 'heroicons-react'
import {observer} from 'mobx-react-lite'
import {Modal, SubmitButton, TextInput, Tooltip} from '../../../components'
import {APIError, createGraph} from '../../../services/api'
import {useGraphStore} from '../../models/store/context'
import {Graph} from '../../models'
import {PasswordInput} from '../../../components'
import {identify} from '../../../services/analytics'

export const GraphSetup: React.FC = observer(() => {
  const [graphId, setGraphId] = useState('')
  const [graphPassword, setGraphPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<APIError | null>(null)

  const graphStore = useGraphStore()
  const graphName = 'My Brain'

  const setupAccount = async (event: FormEvent) => {
    event.preventDefault()

    if (!graphId || !graphPassword) return
    if (loading) return

    setLoading(true)

    const result = await createGraph({
      name: graphName,
      id: graphId,
    })

    if (result?.error) {
      setLoading(false)
      setError(result.error)
      return
    }

    const graph = new Graph({
      $modelId: graphId,
      name: graphName,
    })

    graph.setPassword(graphPassword)

    graphStore.addGraph(graph)

    identify({graph_created: true})
  }

  return (
    <Modal show={true}>
      <div>
        <div className="mt-3 sm:mt-5">
          <div className="text-center">
            <h3
              className="text-2xl leading-6 font-medium text-gray-800 dark:text-near-white"
              id="modal-headline"
            >
              Welcome to Reflect
            </h3>

            <div className="mt-3">
              <p className="text-sm leading-5 text-gray-500">
                Let&apos;s get your account set up.
              </p>
            </div>
          </div>

          <div className="mt-5">
            <form onSubmit={setupAccount}>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-near-white">
                    Username
                  </label>

                  <p className="text-gray-500 dark:text-gray-300 text-xs mt-1">
                    This something you&apos;ll see in the URL all day, so make it count.
                    It has to be unique across all of Reflect.
                  </p>

                  <div className="mt-2 relative">
                    <TextInput
                      value={graphId}
                      onChange={setGraphId}
                      required
                      pattern="[-a-z0-9]{3,}"
                      autoComplete="username"
                      error={error?.type === 'id_unique'}
                    />

                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-help text-gray-400">
                      <Tooltip label="Project identifiers must be at least three characters and can contain letters, numbers, and dashes.">
                        <QuestionMarkCircle />
                      </Tooltip>
                    </div>
                  </div>

                  {error?.type === 'id_unique' && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      Your project identifier is not unique.
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-near-white">
                    Encryption password
                  </label>

                  <p className="text-gray-500 dark:text-gray-300 text-xs mt-1">
                    Pick a long password for end-to-end encryption of your notes.
                    Passwords can{' '}
                    <em>
                      <strong>never be recovered</strong>
                    </em>
                    . We suggest using a password manager to generate a unique single-use
                    password.
                  </p>

                  <div className="mt-2 relative">
                    <PasswordInput
                      newPassword={true}
                      value={graphPassword}
                      onChange={setGraphPassword}
                    />

                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-help text-gray-400">
                      <Tooltip label="Passwords must be at least eight characters. Passwords don't leave your machine and can NEVER be recovered.">
                        <QuestionMarkCircle />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <SubmitButton
                  loading={loading}
                  label="Create account"
                  loadingLabel="Creating account..."
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  )
})
