import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useNoteStore, usePreferenceStore} from '../../../models/store/context'
import {useColorScheme} from '../../../../plugins/use-color-scheme'
import Graph from 'graphology'
import {observer} from 'mobx-react-lite'
import {PixiGraph} from 'pixi-graph'
import {Note} from '../../../models'
import {Coords} from '../../../../components/note-preview/types'
import {Popover} from '../../../../components/note-preview/popover'
import {getSnapshot} from 'mobx-keystone'
import {generateGraph} from './generate-graph'
import {GeneratedGraphSerialization} from './types'
import {LoadingIndicator} from '../../../../components'

export const NotesBrain: React.FC = observer(() => {
  const noteStore = useNoteStore()
  const ref = useRef<HTMLDivElement | null>(null)
  const scheme = useColorScheme()
  const {theme} = usePreferenceStore()
  const [coords, setCoords] = useState<Coords | undefined>()
  const [notePreview, setNotePreview] = useState<Note | undefined>()
  const dark = theme === 'system' ? scheme === 'dark' : theme === 'dark'
  const [loading, setLoading] = useState(true)
  const [pixiGraph, setPixiGraph] = useState<any | undefined>()
  const [graphData, setGraphData] = useState<GeneratedGraphSerialization | undefined>()

  const graph = useMemo(
    () =>
      new Graph<{
        label: string
        x: number
        y: number
        size: number
      }>(),
    [],
  )

  useEffect(() => {
    if (!ref.current) throw new Error('blank ref')

    const result = new PixiGraph({
      container: ref.current,
      graph,
      style: {
        node: {
          size: (node) => node.size * 20 + 10,
          color: '#7957ff',
          label: {
            content: (node) => node.label,
            color: dark ? '#c9d1d9' : '#000',
            fontFamily: 'Inter var',
          },
          border: {
            width: 0,
          },
        },
        edge: {
          width: 3,
          color: dark ? '#252f3f' : '#e6e7eb',
        },
      },
      hoverStyle: {
        edge: {
          width: 3,
          color: '#7957ff',
        },
      },
    })

    result.addListener('nodeClick', (_event, nodeId: string) => {
      noteStore.setSelectedNoteId(nodeId)
      noteStore.setView('edit')
    })

    result.addListener('nodeMouseover', (event, nodeId: string) => {
      setCoords({left: event.clientX, top: event.clientY})
      setNotePreview(noteStore.findById(nodeId))
    })

    result.addListener('nodeMousemove', (event, nodeId: string) => {
      setCoords({left: event.clientX, top: event.clientY})
      setNotePreview(noteStore.findById(nodeId))
    })

    result.addListener('nodeMouseout', () => {
      setCoords(undefined)
      setNotePreview(undefined)
    })

    setPixiGraph(result)

    return () => result?.destroy()
  }, [])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const {notes, backlinks} = noteStore
      const graphData = await generateGraph({
        notes: getSnapshot(notes),
        backlinks,
      })
      setGraphData(graphData)
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    if (!pixiGraph || !graphData) return
    graph.clear()
    graph.import(graphData)
    pixiGraph.resetView()
  }, [pixiGraph, graphData])

  return (
    <div className="flex-1 flex flex-col relative">
      {loading && <LoadingIndicator />}
      {coords && notePreview && <Popover coords={coords} note={notePreview} />}
      <div className="flex-1" ref={ref} />
    </div>
  )
})
