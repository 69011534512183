import React from 'react'
import {ExclamationOutline} from 'heroicons-react'
import {Modal} from '../../../../components'
import {Graph} from '../../../models'

interface RoamImportConfirm {
  show: boolean
  importing?: boolean
  graph: Graph
  onConfirm: () => void
  onClose: () => void
}

export const RoamImportConfirm: React.FC<RoamImportConfirm> = ({
  show,
  graph,
  onConfirm,
  onClose,
  importing = false,
}) => {
  return (
    <Modal show={show}>
      <div className="">
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
            <ExclamationOutline />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3
              className="text-xl leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              Import Roam data
            </h3>

            <h4 className="mt-3 text-base text-gray-900">
              Selected graph: <em>{graph.name}</em>
            </h4>

            <p className="text-sm text-gray-500 mt-3">
              This will import all of your Roam notes into Reflect. We suggest backing up
              your notes before continuing.
            </p>
          </div>
        </div>
      </div>

      <div className="pt-5 sm:flex sm:flex-row-reverse">
        {!importing && (
          <>
            <button
              onClick={onConfirm}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Import data
            </button>

            <button
              onClick={onClose}
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-coolgray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </>
        )}

        {importing && (
          <>
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm opacity-50"
            >
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Importing data...
            </button>
          </>
        )}
      </div>
    </Modal>
  )
}
