import React, {useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {NoteSuggestedBacklinksProps} from './types'
import {useNoteStore} from '../../../../models/store/context'
import {NoteSuggestedIncomingBacklinksItem} from './note-suggested-incoming-backlink-item'
import {SuggestedBacklink} from '../../../../models/note/suggested-backlink'

const NoteSuggestedBacklinks: React.FC<NoteSuggestedBacklinksProps> = observer(
  ({note}) => {
    const [backlinks, setBacklinks] = useState<SuggestedBacklink[]>([])
    const noteStore = useNoteStore()

    useEffect(() => {
      setTimeout(() => {
        setBacklinks(noteStore.suggestedIncomingBacklinks(note))
      })
    }, [note])

    if (!backlinks.length) return null

    return (
      <div className="">
        <div className="flex justify-between items-baseline">
          <h3 className="text-base font-medium leading-5 text-gray-500">
            Suggested backlinks
          </h3>

          <div>
            <a
              onClick={() =>
                backlinks.forEach((bl) => noteStore.addSuggestedBacklink(bl))
              }
              className="text-xs text-purple-550 cursor-pointer"
            >
              Add all
            </a>
          </div>
        </div>

        <div className="mt-3 space-y-3">
          {backlinks.map((bl, i) => (
            <NoteSuggestedIncomingBacklinksItem
              key={i}
              note={note}
              backlink={bl}
              onClickAdd={() => noteStore.addSuggestedBacklink(bl)}
              onClickIgnore={() => note.ignoreSuggestedBacklink(bl)}
            />
          ))}
        </div>
      </div>
    )
  },
)

export {NoteSuggestedBacklinks}
