import {Stripe, loadStripe} from '@stripe/stripe-js'
import {authedJsonPost} from './api'

let stripePromise: Promise<Stripe | null>

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY!)
  }

  return stripePromise
}

export const redirectToPortal = async () => {
  const {url} = await authedJsonPost('/api/stripe/portal')
  window.location.replace(url)
}

type Plans = 'believer'

const PlansToPriceIds = {
  believer: process.env.NEXT_PUBLIC_STRIPE_BELIEVER_PRICE_ID as string,
}

export const redirectToCheckout = async ({plan}: {plan: Plans}) => {
  const priceId = PlansToPriceIds[plan]

  if (!priceId) throw new Error(`invalid plan ${plan}`)

  const {session_id: sessionId} = await authedJsonPost('/api/stripe/checkout', {
    price_id: priceId,
  })

  const stripe = await getStripe()

  if (!stripe) throw new Error('stripe not loaded')

  const {error} = await stripe.redirectToCheckout({
    sessionId,
  })

  if (error) throw new Error('Stripe error:' + error)
}

export default getStripe
