import React from 'react'
import {observer} from 'mobx-react-lite'
import {Note} from '../../../../models'
import {Link} from '../../../../../components'
import {truncate} from 'lodash'
import {useBookStore} from '../../../../models/store/context'

interface NoteBookProps {
  note: Note
}

export const NoteBook: React.FC<NoteBookProps> = observer(({note}) => {
  const bookStore = useBookStore()
  const asin = note.contentMetadataAsin
  const book = asin ? bookStore.findByAsin(asin) : null

  if (!book) return null

  return (
    <div className="bg-white dark:bg-dark-black-900 rounded-md px-4 py-4 shadow-sm flex">
      <div className="overflow-hidden h-32 w-20 flex-none rounded-md">
        <div
          className="bg-cover bg-center bg-no-repeat inline-block h-full w-full"
          style={{backgroundImage: `url(${book.coverSrc})`}}
        />
      </div>

      <div className="flex-1 ml-4">
        <h3 className="text-purple-550 text-lg" title={book.title}>
          <Link href={book.listingUrl}>{truncate(book.title, {length: 30})}</Link>
        </h3>

        <div className="text-sm mt-2 space-y-1 text-gray-600 dark:text-gray-300">
          <p>
            Published:
            <br />
            {book.publicationDateFormatted}
          </p>
          <p>
            Purchased:
            <br />
            {book.purchaseDateFormatted}
          </p>
        </div>
      </div>
    </div>
  )
})
