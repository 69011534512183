import React from 'react'
import {observer} from 'mobx-react-lite'
import {Graph} from '../../../../models'
import {useGraphStore, useNoteStore} from '../../../../models/store/context'
import {DropdownItem} from './dropdown-item'

interface GraphOptionsProps {
  graphs: Graph[]
}

export const GraphOptions: React.FC<GraphOptionsProps> = observer(({graphs}) => {
  const graphStore = useGraphStore()
  const noteStore = useNoteStore()

  const changeGraph = (graph: Graph) => {
    graphStore.setCurrentGraph(graph)
    noteStore.setSelectedNote(null)
  }

  if (!graphs.length) return null

  const renderGraphs = () =>
    graphs.map((graph) => (
      <DropdownItem key={graph.id} onClick={() => changeGraph(graph)}>
        {graph.name}
      </DropdownItem>
    ))

  return <div className="py-1">{renderGraphs()}</div>
})
