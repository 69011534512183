import React, {useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {Note} from '../../../models/note/note'
import {useNoteStore, useRootStore} from '../../../models/store/context'
import {RichText} from '../../../../components/rich-text/rich-text'
import {ErrorBoundaryFallback} from '../../../../components'
import {BacklinkNodeData} from '../../../models'
import {identify} from '../../../../services/analytics'
import {TagNodeData} from '../../../../components/rich-text/plugins/tags'

interface Props {
  note: Note
}

export const NoteEdit: React.FC<Props> = observer(({note}) => {
  const noteStore = useNoteStore()
  const rootStore = useRootStore()
  const graph = note.assertGraph
  const [availableBacklinks, setAvailableBacklinks] = useState<BacklinkNodeData[]>([])
  const [availableMergeNotes, setAvailableMergeNotesBacklinks] = useState<
    BacklinkNodeData[]
  >([])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAvailableBacklinks(rootStore.availableBacklinksForNote(note))
      setAvailableMergeNotesBacklinks(noteStore.availableMergeNotesForNote(note))
    })

    return () => clearTimeout(timeout)
  }, [note])

  const onAddBacklink = (backlink: BacklinkNodeData) => {
    identify({backlink_created: true})
    return noteStore.onAddBacklink(backlink)
  }

  const onAddTag = (tag: TagNodeData) => {
    identify({tag_created: true})
    return graph!.onAddTag(tag)
  }

  return (
    <div className="flex-1 flex flex-col overflow-auto">
      <div className="max-w-screen-md flex-1 flex flex-col">
        <ErrorBoundaryFallback getHtml={() => note.contentHtml}>
          <RichText
            key={note.key}
            availableBacklinks={availableBacklinks}
            availableTags={graph.availableTags}
            availableMergeNotes={availableMergeNotes}
            subject={note.subject}
            content={note.contentSnapshot}
            onChangeContent={(value) => note.updateContent(value, {debounce: true})}
            onAddBacklink={onAddBacklink}
            onAddTag={onAddTag}
            onMergeNote={(mergeData) => noteStore.mergeNotes(note, mergeData.note)}
          />
        </ErrorBoundaryFallback>
      </div>
    </div>
  )
})
