import React from 'react'
import {observer} from 'mobx-react-lite'
import {useNoteStore, useRootStore} from '../../models/store/context'
import {ErrorBoundary, SplitPane} from '../../../components'
import {useMessage} from '../../../plugins/use-message'
import {useHotkeys} from 'react-hotkeys-hook'
import {NotesSearch} from './notes-search/notes-search'
import {
  NoteContextSidebar,
  NotesSidebar,
  NotesDailyEdit,
  NoteEdit,
  NotesBrain,
  NotesList,
} from './notes-dynamic'
import {NoteShow} from './note-show/note-show'

const Notes: React.FC = observer((): JSX.Element | null => {
  const {view: rootView} = useRootStore()
  const notesStore = useNoteStore()
  const {view, selectedNote} = notesStore

  // Electron IPC API
  useMessage((event) => {
    switch (event.data?.command) {
      case 'create-note':
        notesStore.createAndOpenNote()
        break
      case 'select-daily-note':
        notesStore.selectDailyNote()
        break
    }
  })

  // Chrome PWA API
  useHotkeys(
    'command+n, control+n',
    (event) => {
      event.preventDefault()
      notesStore.createAndOpenNote()
    },
    {filter: () => true},
  )

  useHotkeys(
    'command+o, control+o',
    (event) => {
      event.preventDefault()
      notesStore.selectedNote?.togglePinned()
    },
    {filter: () => true},
    [selectedNote],
  )

  useHotkeys(
    'command+d, control+d',
    (event) => {
      event.preventDefault()
      notesStore.selectDailyNote()
    },
    {filter: () => true},
  )

  useHotkeys(
    'command+s, control+s',
    (event) => {
      event.preventDefault()
      notesStore.viewList()
    },
    {filter: () => true},
  )

  const renderEditView = () => {
    if (!selectedNote) return null

    if (!selectedNote.canWrite) {
      return <NoteShow note={selectedNote} />
    }

    return (
      <SplitPane direction="right" name="rhc">
        {selectedNote.daily ? (
          <NotesDailyEdit note={selectedNote} />
        ) : (
          <NoteEdit note={selectedNote} />
        )}
        <NoteContextSidebar note={selectedNote} />
      </SplitPane>
    )
  }

  const renderListView = () => <NotesList />

  const renderBrain = () => <NotesBrain />

  const renderView = (): JSX.Element | null => {
    switch (view) {
      case 'list':
        return renderListView()
      case 'brain':
        return renderBrain()
      case 'edit':
        return renderEditView()
      default:
        return null
    }
  }

  if (!notesStore) throw new Error('instantiated without a note store')
  if (rootView != 'notes') return null

  return (
    <>
      <NotesSearch />
      <SplitPane direction="left" name="lhc">
        <NotesSidebar />
        <ErrorBoundary>{renderView()}</ErrorBoundary>
      </SplitPane>
    </>
  )
})

export {Notes}
