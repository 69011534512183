import {isCollapsed, isPointAtWordEnd, getBlockAbove} from '@udecode/slate-plugins'
import {useCallback, useState} from 'react'
import {Editor, Range, Node} from 'slate'
import {SubjectMergeNodeData, UseSubjectMergeOptions} from './types'
import {getNextIndex, getPreviousIndex, pathEquals} from './utils'

export const useSubjectMerge = (
  mentionables: SubjectMergeNodeData[] = [],
  {onMergeNote, maxSuggestions = 10}: UseSubjectMergeOptions = {},
) => {
  const [targetRange, setTargetRange] = useState<Range | null>(null)
  const [valueIndex, setValueIndex] = useState(-1)
  const [search, setSearch] = useState('')

  const values = mentionables
    .filter((c) => c.value.toLowerCase() == search.toLowerCase())
    .slice(0, maxSuggestions)

  const subjectMerge = useCallback(
    (editor: Editor, data: SubjectMergeNodeData) => {
      if (data && onMergeNote) {
        onMergeNote(data)
      }
      setTargetRange(null)
    },
    [targetRange],
  )

  const onKeyDown = useCallback(
    (e: any, editor: Editor) => {
      if (targetRange && values.length) {
        if (e.key === 'ArrowDown') {
          e.preventDefault()
          return setValueIndex(getNextIndex(valueIndex, values.length - 1))
        }
        if (e.key === 'ArrowUp') {
          e.preventDefault()
          return setValueIndex(getPreviousIndex(valueIndex, values.length - 1))
        }
        if (e.key === 'Escape') {
          e.preventDefault()
          return setTargetRange(null)
        }

        if (['Tab', 'Enter'].includes(e.key)) {
          if (values[valueIndex]) {
            e.preventDefault()

            subjectMerge(editor, values[valueIndex])

            return false
          }
        }
      }
    },
    [values, valueIndex, setValueIndex, targetRange, setTargetRange, subjectMerge],
  )

  const onChange = useCallback(
    (editor: Editor) => {
      const {selection} = editor

      // No cursor
      if (!selection || !isCollapsed(selection)) {
        return setTargetRange(null)
      }

      const cursor = Range.start(selection)

      // Cursor is half-way through a word
      if (!isPointAtWordEnd(editor, {at: cursor})) {
        return setTargetRange(null)
      }

      const blockAbove = getBlockAbove(editor, {at: cursor})

      if (!blockAbove) {
        return setTargetRange(null)
      }

      const [node, path] = blockAbove

      // Make sure we're in the subject header
      if (node.type != 'h1' || !pathEquals(path, [0, 0])) {
        return setTargetRange(null)
      }

      const text = Node.string(node)

      if (!text) {
        return setTargetRange(null)
      }

      const range: Range = {
        anchor: Editor.point(editor, path),
        focus: cursor,
      }

      setTargetRange(range)
      setSearch(text)
      setValueIndex(-1)
    },
    [setTargetRange, setSearch, setValueIndex],
  )

  return {
    search,
    index: valueIndex,
    target: targetRange,
    values,
    onChange,
    onKeyDown,
    subjectMerge,
  }
}
