import {Graph, NoteStore} from '../../../models'
import {SearchResult, SearchType} from './types'

const TAG_RE = /^#/

export const getNextIndex = (i: number, max: number) => (i >= max ? 0 : i + 1)
export const getPreviousIndex = (i: number, max: number) => (i <= 0 ? max : i - 1)

export const getSearchType = (query: string | null | undefined): SearchType => {
  if (!query) return 'blank'
  if (TAG_RE.test(query)) return 'tags'
  return 'notes'
}

export const getTagResults = (query: string, graph: Graph): SearchResult[] => {
  const strippedQuery = query.replace(TAG_RE, '')
  const tags = graph.filteredTags(strippedQuery)

  return tags.map((tag) => ({
    tag,
    id: tag,
    title: `#${tag}`,
  }))
}

export const getNoteResults = (query: string, noteStore: NoteStore): SearchResult[] => {
  const notes = noteStore.filteredNotes(query)

  return notes.map((note) => ({
    note,
    id: note.id,
    title: note.title,
    snippet: note.snippet,
    updated: note.updatedAtFormatted,
  }))
}
