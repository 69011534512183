import clsx from 'clsx'
import React, {MouseEvent, MouseEventHandler, useState} from 'react'

interface Props {
  onClick: MouseEventHandler
}

export const PendingLink: React.FC<Props> = ({children, onClick}) => {
  const [pending, setPending] = useState(false)

  const onClickLink = (event: MouseEvent) => {
    if (pending) return false
    setPending(true)
    onClick(event)
  }

  return (
    <a
      onClick={onClickLink}
      className={clsx([
        'mt-8 block w-full cursor-pointer relative',
        'bg-gray-800 border border-gray-800 rounded-md py-2',
        'text-sm font-semibold text-white text-center',
        'hover:bg-gray-900 transition duration-300',
        pending && 'opacity-50 cursor-auto',
      ])}
    >
      {children}

      {pending && (
        <>
          <div className="absolute inset-0 flex items-center px-2">
            <svg
              className="animate-spin h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </>
      )}
    </a>
  )
}
