import React, {useEffect, useState} from 'react'
import {
  AppRegionDrag,
  ErrorBoundary,
  Theme,
  ThemedLoadingIndicator,
} from '../../components'
import Head from 'next/head'
import {useServiceWorkerUpdate} from 'plugins/use-service-worker-update'
import {setIndexCookie} from 'plugins/index-cookie'
import {RootStore} from 'app/models/store/root-store'
import {setupRootStore} from 'app/models/store/root-store-setup'
import {RootStoreProvider} from 'app/models/store/context'
import {Loader} from './loader'
import {AppProps, VERSION} from './types'
import {useAnalytics} from 'services/analytics'
import {useRouter} from 'next/router'
import {getRouterQuery} from './helpers'

export const App: React.FC<AppProps> = () => {
  useAnalytics()
  useServiceWorkerUpdate()
  setIndexCookie()

  const router = useRouter()

  const [rootStore, setRootStore] = useState<RootStore | undefined>()

  useEffect(() => {
    if (!router.isReady) return

    console.log(`Loaded app version: ${VERSION}`)
    console.log('Setting up root store...')

    // Get default query params
    const defaults = getRouterQuery(router)
    setupRootStore(defaults).then(setRootStore)
  }, [router.isReady])

  if (!rootStore) return <ThemedLoadingIndicator />

  return (
    <main className="overflow-hidden">
      <Head>
        <link rel="manifest" href="/manifest.json" />
      </Head>

      <ErrorBoundary>
        <RootStoreProvider value={rootStore}>
          <Theme>
            <AppRegionDrag />
            <Loader />
          </Theme>
        </RootStoreProvider>
      </ErrorBoundary>
    </main>
  )
}
