import {NextRouter} from 'next/router'
import {slugToId} from '../../plugins/to-id'

export const getRouterQuery = (router: NextRouter) => {
  if (!router.isReady) throw new Error('router is not ready')

  const graphId = router.query.graph_id as string | undefined
  const slug = router.query.slug as string | undefined
  const preferencesView = router.query.preferences_view as string | undefined
  const noteId = slug && slugToId(slug)

  return {
    graphId,
    noteId,
    preferencesView,
  }
}
