import {Check} from 'heroicons-react'
import {observer} from 'mobx-react-lite'
import React from 'react'
import {redirectToCheckout, redirectToPortal} from '../../../../services/stripe'
import {useUserStore} from '../../../models/store/context'
import {PendingLink} from './pending-link'

export const PreferencesBilling: React.FC = observer(() => {
  const {currentUser} = useUserStore()

  const paidNumber = currentUser?.traits.paid_number as number | undefined

  return (
    <>
      <div className="px-10 py-10 flex flex-col">
        <h3 className="text-2xl leading-7 font-medium text-gray-900 dark:text-near-white">
          Plans &amp; billing
        </h3>

        {paidNumber && (
          <>
            <div className="bg-yellow-50 text-yellow-600 dark:bg-dark-black-800 text-sm mt-6 max-w-xl px-5 py-5 flex items-center space-x-5 border-l-4 border-yellow-400">
              <div className="flex flex-col space-y-2 leading-5 select-text">
                <p>
                  You are customer <strong>#{paidNumber}</strong> of Reflect.
                </p>
                <p>
                  As thanks for being an early customer you have{' '}
                  <em>lifetime priority support</em>. If you ever need help, or want to
                  provide feedback, my email is{' '}
                  <a href="mailto:alex@reflect.app" className="underline">
                    alex@reflect.app
                  </a>{' '}
                  and mobile is{' '}
                  <a href="tel:+14156247665" className="underline">
                    +1 415 624 7665
                  </a>
                  .
                </p>
                <p>Love Alex</p>
              </div>
            </div>
          </>
        )}

        <div className="mt-10 sm:mt-9">
          <div className="space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-3xl">
            <div className="border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm divide-y divide-gray-200 dark:divide-gray-700">
              <div className="p-6">
                <h2 className="text-xl leading-6 font-medium text-gray-900 dark:text-white">
                  Believer
                </h2>
                <p className="mt-4 text-sm text-gray-500 dark:text-near-white">
                  Support the cause and help make this business sustainable.
                </p>
                <p className="mt-8">
                  <span className="text-4xl font-extrabold text-gray-900 dark:text-white">
                    $120
                  </span>
                  <span className="text-base font-medium text-gray-500 dark:text-near-white">
                    /year
                  </span>
                </p>

                {!currentUser?.stripeSubscriptionId && (
                  <>
                    <PendingLink onClick={() => redirectToCheckout({plan: 'believer'})}>
                      Become a Believer
                    </PendingLink>
                  </>
                )}

                {!!currentUser?.stripeSubscriptionId && (
                  <>
                    <PendingLink onClick={() => redirectToPortal()}>
                      Manage subscription
                    </PendingLink>
                  </>
                )}
              </div>
              <div className="pt-6 pb-8 px-6">
                <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase dark:text-near-white">
                  What&apos;s included
                </h3>
                <ul className="mt-6 space-y-4">
                  <li className="flex space-x-3">
                    <Check className="flex-shrink-0 h-5 w-5 text-green-500" />
                    <span className="text-sm text-gray-500 dark:text-near-white">
                      Networked note-taking
                    </span>
                  </li>

                  <li className="flex space-x-3">
                    <Check className="flex-shrink-0 h-5 w-5 text-green-500" />
                    <span className="text-sm text-gray-500 dark:text-near-white">
                      End to end encryption
                    </span>
                  </li>

                  <li className="flex space-x-3">
                    <Check className="flex-shrink-0 h-5 w-5 text-green-500" />
                    <span className="text-sm text-gray-500 dark:text-near-white">
                      Offline sync
                    </span>
                  </li>

                  <li className="flex space-x-3">
                    <Check className="flex-shrink-0 h-5 w-5 text-green-500" />
                    <span className="text-sm text-gray-500 dark:text-near-white">
                      Kindle highlights import
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})
