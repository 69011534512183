import {getNode} from '@udecode/slate-plugins'
import {Editor, Transforms} from 'slate'

export const selectEndOfSubject = (editor: Editor) => {
  const headerPath = [0, 0]
  const header = getNode(editor, headerPath)

  if (!header || header.type != 'h1') return false

  Transforms.select(editor, Editor.end(editor, headerPath))

  return true
}
