import {observer} from 'mobx-react-lite'
import React from 'react'
import {FadeInTransition} from '../../../components'
import {useNotificationStore} from '../../models/store/context'
import {NotificationItem} from './notification-item'

export const Notifications: React.FC = observer(() => {
  const {notifications} = useNotificationStore()

  return (
    <FadeInTransition in={!!notifications.length}>
      <div className="fixed right-0 bottom-0 z-10 flex flex-col space-y-5 items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
        {notifications.map((noti) => (
          <NotificationItem key={noti.id} notification={noti} />
        ))}
      </div>
    </FadeInTransition>
  )
})
