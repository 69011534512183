import * as React from 'react'
import {useEffect, useRef} from 'react'
import {classNamesFunction, styled} from '@uifabric/utilities'
import {ReactEditor, useSlate} from 'slate-react'
import {getSubjectMergeSelectStyles} from './subject-merge-select.styles'
import {
  SubjectMergeSelectProps,
  SubjectMergeSelectStyleProps,
  SubjectMergeSelectStyles,
} from './subject-merge.types'
import {PortalBody} from '../../../..'

const getClassNames = classNamesFunction<
  SubjectMergeSelectStyleProps,
  SubjectMergeSelectStyles
>()

export const SubjectMergeSelectBase = ({
  className,
  styles,
  at,
  options,
  valueIndex,
  onClickSubjectMerge,
  ...props
}: SubjectMergeSelectProps) => {
  const classNames = getClassNames(styles, {
    className,
  })

  const ref = useRef<HTMLDivElement>(null)
  const editor = useSlate()

  useEffect(() => {
    if (at) {
      const el = ref.current
      const domRange = ReactEditor.toDOMRange(editor, at)
      const rect = domRange.getBoundingClientRect()
      if (el) {
        el.style.top = `${rect.top + window.pageYOffset + 40}px`
        el.style.left = `${rect.left + window.pageXOffset}px`
      }
    }
  }, [options.length, editor, at])

  if (!at) {
    return null
  }

  return (
    <PortalBody>
      <div ref={ref} className={classNames.root} {...props}>
        {options.map((option, i) => (
          <div
            key={`${i}${option.value}`}
            className={
              i === valueIndex
                ? classNames.subjectMergeItemSelected
                : classNames.subjectMergeItem
            }
            onMouseDown={(event) => {
              event.preventDefault()
              onClickSubjectMerge?.(editor, option)
            }}
          >
            Merge note with &apos;{option.value}&apos;?
          </div>
        ))}
      </div>
    </PortalBody>
  )
}

export const SubjectMergeSelect = styled<
  SubjectMergeSelectProps,
  SubjectMergeSelectStyleProps,
  SubjectMergeSelectStyles
>(SubjectMergeSelectBase, getSubjectMergeSelectStyles, undefined, {
  scope: 'SubjectMergeSelect',
})
