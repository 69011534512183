import {EditablePlugins} from '@udecode/slate-plugins'
import {debounce, isEqual} from 'lodash'
import dynamic from 'next/dynamic'
import React, {useCallback, useEffect, useLayoutEffect, useMemo, useState} from 'react'
import {Editor} from 'slate'
import {ReactEditor, Slate} from 'slate-react'
import {createEditor} from './editor'
import {defaultValue} from './options'
import {plugins} from './plugins'
import {useBacklink} from './plugins/backlink'
import {BacklinkSelect} from './plugins/backlink/components/BacklinkSelect'
import {SubjectMergeSelect, useSubjectMerge} from './plugins/subject-merge'
import {TagSelect, useTag} from './plugins/tags'
import {selectEndOfNote} from './plugins/transforms/select-end-of-note'
import {selectEndOfSubject} from './plugins/transforms/select-end-of-subject'
import {selectStartOfNote} from './plugins/transforms/select-start-of-note'
import {RichTextProps} from './rich-text.props'

const RichText: React.FC<RichTextProps> = ({
  key,
  content,
  subject,
  availableBacklinks = [],
  availableTags = [],
  availableMergeNotes = [],
  selected = true,
  selectingEdge,
  onChangeContent,
  onAddBacklink,
  onAddTag,
  onMergeNote,
  onFocus,
  onNavigatePrevious,
  onNavigateNext,
}) => {
  const editor = useMemo(() => createEditor(), [])
  const [contentState, setContentState] = useState(content)

  useEffect(() => {
    if (contentState.length) {
      editor.children = contentState
      Editor.normalize(editor, {force: true})
    }
  }, [key])

  const {
    addBacklink: backlinkAdd,
    onChange: backlinkChange,
    onKeyDown: backlinkKeyDown,
    search: backlinkSearch,
    index: backlinkIndex,
    target: backlinkTarget,
    values: backlinkValues,
  } = useBacklink(availableBacklinks, {onAddBacklink})

  const {
    addTag: tagAdd,
    onChange: tagChange,
    onKeyDown: tagKeyDown,
    search: tagSearch,
    index: tagIndex,
    target: tagTarget,
    values: tagValues,
  } = useTag(availableTags, {onAddTag})

  const {
    subjectMerge: subjectMerge,
    onChange: subjectMergeChange,
    onKeyDown: subjectMergeKeyDown,
    search: subjectMergeSearch,
    index: subjectMergeIndex,
    target: subjectMergeTarget,
    values: subjectMergeValues,
  } = useSubjectMerge(availableMergeNotes, {onMergeNote})

  useEffect(() => {
    if (selected && !ReactEditor.isFocused(editor)) {
      ReactEditor.focus(editor)

      // Necessary to use timeout here, because selecting doesn't
      // work properly if done in the same run loop as focusing.
      setTimeout(() => {
        if (selectingEdge === 'bottom') {
          selectEndOfNote(editor)
        } else if (selectingEdge === 'top') {
          selectStartOfNote(editor)
        } else if (selectingEdge === 'default') {
          selectEndOfSubject(editor)
        }
      }, 0)
    }
  }, [key, selected, selectingEdge])

  const debouncedOnChangeContent = useMemo(() => debounce(onChangeContent, 500), [])

  useLayoutEffect(() => {
    if (contentState != content) {
      debouncedOnChangeContent(contentState)
    }
  }, [contentState])

  const [spellCheck, setSpellCheck] = useState(true)
  const debouncedSetSpellCheck = useMemo(() => debounce(setSpellCheck, 500), [])

  const toggleSpellCheck = () => {
    // Slate has a spell checking bug where spell checking gets invoked too
    // aggressively. We can work around this by toggling it when the user types.
    // https://rb.gy/qycfpa
    setSpellCheck(false)
    debouncedSetSpellCheck(true)
  }

  const editorKeyDown = useCallback(
    (event: React.KeyboardEvent, editor: Editor) => {
      const path = editor.selection?.anchor.path

      const [, firstPath] = Editor.first(editor, [])
      const [, lastPath] = Editor.last(editor, [])

      if (isEqual(path, firstPath) && event.key === 'ArrowUp') {
        event.preventDefault()
        onNavigatePrevious?.()
      } else if (isEqual(path, lastPath) && event.key === 'ArrowDown') {
        event.preventDefault()
        onNavigateNext?.()
      }
    },
    [onNavigatePrevious, onNavigateNext],
  )

  return (
    <>
      <div
        className="prose prose-sm text-black dark:prose-dark dark:text-near-white
      flex-grow flex-shrink-0 max-w-none flex flex-col select-text"
      >
        <Slate
          editor={editor}
          value={contentState[0] ? contentState : defaultValue(subject)}
          onChange={(newValue) => {
            backlinkChange(editor)
            tagChange(editor)
            subjectMergeChange(editor)

            // If the content has actually changed (vs the selection)
            if (contentState !== newValue) {
              toggleSpellCheck()
              setContentState(newValue)
            }
          }}
        >
          <EditablePlugins
            spellCheck={spellCheck}
            tabIndex={1}
            style={{
              fontSize: 'inherit',
              lineHeight: 'inherit',
            }}
            className="flex-grow py-10 px-10"
            plugins={plugins}
            onFocus={onFocus}
            onKeyDown={[editorKeyDown, backlinkKeyDown, tagKeyDown, subjectMergeKeyDown]}
            onKeyDownDeps={[
              backlinkIndex,
              backlinkSearch,
              backlinkTarget,
              tagIndex,
              tagSearch,
              tagTarget,
              subjectMergeIndex,
              subjectMergeSearch,
              subjectMergeTarget,
            ]}
          />

          <BacklinkSelect
            at={backlinkTarget}
            search={backlinkSearch}
            valueIndex={backlinkIndex}
            options={backlinkValues}
            onClickBacklink={backlinkAdd}
          />

          <TagSelect
            at={tagTarget}
            search={tagSearch}
            valueIndex={tagIndex}
            options={tagValues}
            onClickTag={tagAdd}
          />

          <SubjectMergeSelect
            at={subjectMergeTarget}
            search={subjectMergeSearch}
            valueIndex={subjectMergeIndex}
            options={subjectMergeValues}
            onClickSubjectMerge={subjectMerge}
          />
        </Slate>
      </div>
    </>
  )
}

const RichTextWithoutSSR = dynamic(() => Promise.resolve(RichText), {
  ssr: false,
})

export {RichTextWithoutSSR as RichText}
