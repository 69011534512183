import {observer} from 'mobx-react-lite'
import React, {useState} from 'react'
import {TextInput} from '../../../../components'
import {Graph} from '../../../models'
import {GraphEditPasswordModal} from './graph-edit-password-modal'

interface GraphEditProps {
  graph: Graph
}

export const GraphEdit: React.FC<GraphEditProps> = observer(({graph}) => {
  const [graphName, setGraphName] = useState(graph.name || '')
  const [showEditPassword, setShowEditPassword] = useState(false)

  const updateName = (name: string) => {
    if (!name) return

    setGraphName(name)
    graph.setName(name)
    graph.update()
  }

  return (
    <>
      <GraphEditPasswordModal
        graph={graph}
        show={showEditPassword}
        onClose={() => setShowEditPassword(false)}
      />

      <div className="flex flex-col">
        <h3 className="text-2xl leading-7 font-medium text-gray-900 dark:text-near-white">
          Edit Graph
        </h3>

        <div className="mt-6 sm:mt-5">
          <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t border-gray-100 dark:border-gray-900 sm:pt-5">
            <label className="block text-sm font-medium leading-5 text-gray-700 dark:text-near-white sm:mt-px sm:pt-2">
              Name
            </label>

            <div className="mt-1 sm:mt-0 sm:col-span-2 flex flex-col space-y-3">
              <div className="max-w-72">
                <TextInput required value={graphName} onChange={updateName} />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 sm:mt-5">
          <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t border-gray-100 dark:border-gray-900 sm:pt-5">
            <label className="block text-sm font-medium leading-5 text-gray-700 dark:text-near-white sm:mt-px sm:pt-2">
              Identifier
            </label>

            <div className="mt-1 sm:mt-0 sm:col-span-2 flex flex-col space-y-3">
              <div className="max-w-72 select-text text-base">{graph.id}</div>
            </div>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:pt-3">
          <div className="sm:col-start-2">
            <a
              className="text-xs text-indigo-600 dark:text-near-white dark:hover:text-purple-500 transition duration-300 cursor-pointer font-medium"
              onClick={() => setShowEditPassword(true)}
            >
              Change encryption password
            </a>
          </div>
        </div>
      </div>
    </>
  )
})
