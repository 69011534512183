import React from 'react'
import {useNoteStore} from '../../../models/store/context'
import useDimensions from 'react-cool-dimensions'
import {Virtuoso} from 'react-virtuoso'
import {NotesListItem} from './notes-list-item'
import {observer} from 'mobx-react-lite'
import {TagsToggle} from './tags-toggle'
import {Note} from '../../../models'
import {TRASH_QUERY} from './types'

export const NotesList: React.FC = observer(() => {
  const noteStore = useNoteStore()
  const {searchQuery} = noteStore
  const {ref, height} = useDimensions()

  let notes: Note[] = noteStore.orderedNotes

  if (searchQuery === TRASH_QUERY) {
    notes = noteStore.orderedDeletedNotes
  } else if (searchQuery) {
    notes = noteStore.filteredNotes(searchQuery)
  }

  return (
    <div className="flex flex-1 flex-col pt-4">
      <div className="border-b border-gray-100 dark:border-gray-900 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-9">
        <div className="flex-1 min-w-0">
          <input
            type="text"
            value={searchQuery || ''}
            className="text-lg border-none p-0 font-medium bg-transparent leading-6 text-gray-900 dark:text-near-white outline-none focus:ring-0 placeholder-gray-900 dark:placeholder-white"
            onChange={(event) => noteStore.setSearchQuery(event.target.value)}
            placeholder="Notes"
          />
        </div>

        <div>
          <TagsToggle />
        </div>
      </div>

      <div className="flex-1 flex flex-col">
        <div className="flex-1 flex flex-col overflow-hidden relative">
          <div className="flex-1 flex flex-col">
            <div className="flex-none bg-coolgray-50 dark:bg-dark-black-900 border-b border-gray-100 dark:border-gray-900 grid grid-cols-7 gap-4 px-6 lg:px-7 py-3 text-left text-xs font-semibold text-gray-400 dark:text-near-white uppercase tracking-widest">
              <div className="lg:pl-2 col-span-2">Subject</div>
              <div className="col-span-3">Snippet</div>
              <div className="">Tags</div>
              <div className="">Updated at</div>
            </div>

            <div className="flex-1" ref={ref as any}>
              <Virtuoso
                style={{width: '100%', height}}
                totalCount={notes.length}
                itemContent={(index) => (
                  <NotesListItem
                    key={notes[index]?.id}
                    note={notes[index]}
                    onClick={() => noteStore.editNote(notes[index])}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
