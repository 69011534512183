import React from 'react'
import {
  LogoTwitter,
  LogoFacebook,
  LogoGithub,
  LogoLinkedin,
} from '@styled-icons/ionicons-solid'
import {truncate} from 'lodash'
import {Link} from '../../../../../components'

export const Person = ({person}: {person: any}) => {
  const linkedInUrl =
    person.linkedin.handle && `https://linkedin.com/${person.linkedin.handle}`
  const site = person.site || linkedInUrl

  return (
    <div className="flex space-x-4">
      <div className="rounded-md overflow-hidden h-16 w-16 bg-coolgray-50 dark:bg-black">
        {person.avatar && (
          <div
            className="bg-contain bg-center bg-no-repeat inline-block h-full w-full"
            style={{backgroundImage: `url(${person.avatar})`}}
          />
        )}
      </div>

      <div className="flex-1 flex flex-col space-y-2">
        <h3 className="text-purple-550 leading-5 text-lg">
          {site ? (
            <Link href={site}>{person.name?.fullName}</Link>
          ) : (
            person.name?.fullName
          )}
        </h3>

        {person.bio && (
          <div className="leading-5 text-sm" title={person.bio}>
            {truncate(person.bio, {length: 100})}
          </div>
        )}

        <div className="flex space-x-3 items-center">
          {person.twitter?.handle && (
            <Link
              href={`https://twitter.com/${person.twitter.handle}`}
              className="text-gray-400 hover:text-purple-550 transition-all duration-300 ease-in-out"
            >
              <LogoTwitter size={18} />
            </Link>
          )}

          {person.github?.handle && (
            <Link
              href={`https://github.com/${person.github.handle}`}
              className="text-gray-400 hover:text-purple-550 transition-all duration-300 ease-in-out"
            >
              <LogoGithub size={18} />
            </Link>
          )}

          {person.facebook?.handle && (
            <Link
              href={`https://facebook.com/${person.facebook.handle}`}
              className="text-gray-400 hover:text-purple-550 transition-all duration-300 ease-in-out"
            >
              <LogoFacebook size={18} />
            </Link>
          )}

          {person.linkedin?.handle && (
            <Link
              href={`https://linkedin.com/${person.linkedin.handle}`}
              className="text-gray-400 hover:text-purple-550 transition-all duration-300 ease-in-out"
            >
              <LogoLinkedin size={18} />
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}
