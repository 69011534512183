import React from 'react'
import clsx from 'clsx'
import {MouseEventHandler} from 'react'

interface DropdownItemProps {
  onClick?: MouseEventHandler
}

const DropdownItem: React.FC<DropdownItemProps> = ({onClick, children}) => (
  <a
    onClick={onClick}
    className={clsx([
      'cursor-pointer block px-4 py-2',
      'text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900',
      'dark:text-near-white dark:hover:bg-gray-800',
    ])}
    role="menuitem"
  >
    {children}
  </a>
)

export {DropdownItem}
