import {useEffect} from 'react'

const THIRTY_SECONDS = 1000 * 30 * 2

export const useServiceWorkerUpdate = () => {
  const handler = async () => {
    const workers = await navigator.serviceWorker.getRegistrations()

    for (const worker of workers) {
      await worker.update()
    }
  }

  useEffect(() => {
    const interval = setInterval(handler, THIRTY_SECONDS)
    return () => clearInterval(interval)
  }, [])
}
