import React, {useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {Note} from '../../../../models'
import {MeetingItem} from './meeting-item'
import {useMeetingStore} from '../../../../models/store/context'

interface Props {
  note: Note
}

export const NoteMeetings: React.FC<Props> = observer(({note}) => {
  const meetingStore = useMeetingStore()
  const [loading, setLoading] = useState(false)
  const enabled = note.daily

  const fetchMeetings = async () => {
    setLoading(true)
    await meetingStore.fetchMeetingsForDay(note.createdAt)
    setLoading(false)
  }

  useEffect(() => {
    if (!enabled) return
    fetchMeetings()
  }, [note.id])

  if (!enabled) return null

  const meetings = meetingStore.meetingsForDay(note.createdAt)

  return (
    <div>
      <h3 className="text-sm font-semibold uppercase tracking-widest leading-5 text-gray-400">
        Meetings
      </h3>

      <div className="mt-4 bg-white dark:bg-dark-black-900 rounded-md shadow-sm transition-all duration-300">
        <div className="divide-y divide-bluegray-100 dark:divide-gray-900">
          {meetings.map((meeting) => (
            <MeetingItem key={meeting.id} note={note} meeting={meeting} />
          ))}
        </div>

        {!loading && !meetings.length && (
          <>
            <div className="min-h-16 flex items-center justify-center text-gray-400 text-sm">
              No meetings found
            </div>
          </>
        )}

        {loading && !meetings.length && (
          <>
            <div className="min-h-16 flex items-center justify-center text-gray-400 text-sm">
              Loading...
            </div>
          </>
        )}
      </div>
    </div>
  )
})
