import dynamic from 'next/dynamic'
import {Note} from '../../models'
interface NoteProp {
  note: Note
}

export const NoteContextSidebar = dynamic<NoteProp>(() =>
  import('./note-context-sidebar/note-context-sidebar').then(
    (lib) => lib.NoteContextSidebar,
  ),
)

export const NotesSidebar = dynamic<unknown>(() =>
  import('./notes-sidebar/notes-sidebar').then((lib) => lib.NotesSidebar),
)

export const NotesDailyEdit = dynamic<NoteProp>(() =>
  import('./notes-daily-edit/notes-daily-edit').then((lib) => lib.NotesDailyEdit),
)

export const NoteEdit = dynamic<NoteProp>(() =>
  import('./note-edit/note-edit').then((lib) => lib.NoteEdit),
)

export const NotesBrain = dynamic<unknown>(
  () => import('./notes-brain/notes-brain').then((lib) => lib.NotesBrain),
  {ssr: false},
)

export const NotesList = dynamic<unknown>(() =>
  import('./notes-list/notes-list').then((lib) => lib.NotesList),
)
