import React, {useEffect, useRef, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {useNoteStore, useUserStore} from '../../../../models/store/context'
import clsx from 'clsx'
import {AccountDropdown} from './account-dropdown'
import {Position} from './types'
import {NewButton} from './new-button'

const AccountNav: React.FC = observer(() => {
  const noteStore = useNoteStore()
  const {currentUser} = useUserStore()
  const [showDropdown, setShowDropdown] = useState(false)
  const [position, setPosition] = useState<Position>({left: 0, top: 0})
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const onClick = () => {
      setShowDropdown(false)
    }

    window.addEventListener('click', onClick, true)

    return function cleanup() {
      window.removeEventListener('click', onClick, true)
    }
  }, [])

  useEffect(() => {
    if (!ref.current) throw new Error('ref blank')

    const {top, left} = ref.current.getBoundingClientRect()

    setPosition({top, left})
  }, [showDropdown])

  return (
    <>
      <div className="flex-none mx-4 z-10">
        <div className={'flex justify-end items-center space-x-3'}>
          <div ref={ref}>
            <div
              onClick={() => setShowDropdown(!showDropdown)}
              className={clsx('flex items-center cursor-pointer')}
            >
              <div className="rounded-md overflow-hidden h-8 w-8 bg-gray-200 dark:bg-black">
                {currentUser?.photoUrl && (
                  <div
                    className="bg-contain bg-center bg-no-repeat inline-block h-full w-full"
                    style={{backgroundImage: `url(${currentUser.photoUrl})`}}
                  />
                )}
              </div>
            </div>
          </div>

          <NewButton onClick={() => noteStore.createAndOpenNote()} />
        </div>
      </div>

      <AccountDropdown position={position} show={showDropdown} />
    </>
  )
})

export {AccountNav}
