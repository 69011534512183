import React, {useEffect} from 'react'
import {useState} from 'react'
import {useHotkeys} from 'react-hotkeys-hook'
import {identify} from '../../../services/analytics'
import {Shortcut} from './shortcut'
import {FullModal} from '../../../components'

export const ShortcutsModal: React.FC = () => {
  const [show, setShow] = useState(false)

  useHotkeys(
    'command+/, control+/',
    (event) => {
      event.preventDefault()
      setShow(true)
    },
    {filter: () => true},
  )

  useHotkeys('escape', () => setShow(false))

  useEffect(() => {
    if (show) {
      identify({shortcuts_opened: true})
    }
  }, [show])

  if (!show) return null

  return (
    <FullModal onClose={() => setShow(false)}>
      <div className="md:mt-5">
        <h3 className="leading-6 font-medium text-gray-900 dark:text-near-white text-2xl">
          Keyboard shortcuts
        </h3>

        <div className="shortcuts lg:mt-10 mt-6 prose min-w-3xl dark:prose-dark grid grid-cols-2 gap-15">
          <div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <Shortcut keys={['[[']} />
                  </td>
                  <td>Backlink note</td>
                </tr>

                <tr>
                  <td>
                    <Shortcut keys={['command', 'n']} />
                  </td>
                  <td>New note</td>
                </tr>

                <tr>
                  <td>
                    <Shortcut keys={['command', 'd']} />
                  </td>
                  <td>Go to Daily Note</td>
                </tr>

                <tr>
                  <td>
                    <Shortcut keys={['command', 'p']} />
                  </td>
                  <td>Search notes</td>
                </tr>

                <tr>
                  <td>
                    <Shortcut keys={['command', 's']} />
                  </td>
                  <td>List notes</td>
                </tr>

                <tr>
                  <td>
                    <Shortcut keys={['command', 'o']} />
                  </td>
                  <td>Pin note</td>
                </tr>

                <tr>
                  <td>
                    <Shortcut keys={['control', 'shift', 'command', 'leftArrow']} />
                  </td>
                  <td>Collapse list</td>
                </tr>

                <tr>
                  <td>
                    <Shortcut keys={['control', 'shift', 'command', 'rightArrow']} />
                  </td>
                  <td>Expand list</td>
                </tr>

                <tr>
                  <td>
                    <Shortcut keys={['command', '[']} />
                  </td>
                  <td>Go back</td>
                </tr>

                <tr>
                  <td>
                    <Shortcut keys={['command', ']']} />
                  </td>
                  <td>Go forward</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <Shortcut keys={['command', 'b']} />
                  </td>
                  <td>Bold</td>
                </tr>

                <tr>
                  <td>
                    <Shortcut keys={['command', 'i']} />
                  </td>
                  <td>Italic</td>
                </tr>

                <tr>
                  <td>
                    <Shortcut keys={['##']} />
                  </td>
                  <td>Big heading</td>
                </tr>

                <tr>
                  <td>
                    <Shortcut keys={['###']} />
                  </td>
                  <td>Little heading</td>
                </tr>

                <tr>
                  <td>
                    <Shortcut keys={['+']} />
                  </td>
                  <td>Todo-list</td>
                </tr>

                <tr>
                  <td>
                    <Shortcut keys={['`']} />
                  </td>
                  <td>Inline code</td>
                </tr>

                <tr>
                  <td>
                    <Shortcut keys={['``']} />
                  </td>
                  <td>Multi-line code</td>
                </tr>

                <tr>
                  <td>
                    <Shortcut keys={['-']} />
                  </td>
                  <td>List</td>
                </tr>

                <tr>
                  <td>
                    <Shortcut keys={['control', 'command', 'space']} />
                  </td>
                  <td>Insert emoji</td>
                </tr>

                <tr>
                  <td>
                    <Shortcut keys={['command', 'v']} />
                  </td>
                  <td>Paste hyperlink (select word)</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </FullModal>
  )
}
