import React from 'react'
import {NoteIncomingBacklinks} from './note-incoming-backlinks/note-incoming-backlinks'
import {NoteContextSidebarProps} from './note-context-sidebar.props'
import {NoteSuggestedBacklinks} from './note-suggested-backlinks/note-suggested-backlinks'
import {NoteToolbar} from './note-toolbar/note-toolbar'
import {NoteOutgoingBacklinks} from './note-outgoing-backlinks/note-outgoing-backlinks'
import {NoteEnrichment} from './note-enrichment/note-enrichment'
import {NoteBook} from './note-book/note-book'
import {NoteSuggestContact} from './note-suggested-contact/note-suggest-contact'
import {NoteMeetings} from './note-meetings/note-meetings'

const NoteContextSidebar: React.FC<NoteContextSidebarProps> = ({note}) => {
  return (
    <div className="flex-1 flex flex-col overflow-hidden dark:bg-dark-black-800 dark:border-dark-black-800 bg-coolgray-50">
      <div className="flex-1 flex flex-col overflow-auto px-4 py-4 pl-6 pt-5 space-y-5">
        <NoteToolbar note={note} />

        <NoteSuggestContact note={note} />
        <NoteBook note={note} />
        <NoteEnrichment note={note} />
        <NoteIncomingBacklinks note={note} />
        <NoteOutgoingBacklinks note={note} />
        <NoteSuggestedBacklinks note={note} />

        <NoteMeetings note={note} />
      </div>
    </div>
  )
}

export {NoteContextSidebar}
