import React from 'react'
import {observer} from 'mobx-react-lite'
import {useNoteStore} from '../../../../models/store/context'
import {ArrowLeftOutline} from 'heroicons-react'
import {NoteIncomingBacklinkItemProps} from './types'
import {NotePreview} from '../../../../../components'

const NoteIncomingBacklinkItem: React.FC<NoteIncomingBacklinkItemProps> = observer(
  ({backlink}) => {
    const noteStore = useNoteStore()

    const note = noteStore.findById(backlink.fromNoteId)

    if (!note) return null

    return (
      <a
        className="block border border-transparent active:border-purple-300 active:shadow-outline-purple cursor-pointer rounded-md bg-white dark:bg-dark-black-900 shadow-sm px-4 py-4 pb-3 hover:ring-purple-500 transition ease-in-out duration-300 space-y-1"
        onClick={() => noteStore.setSelectedNoteId(note.id)}
      >
        <div className="flex items-center text-base leading-5 text-purple-550">
          <span className="flex-1 truncate">
            <NotePreview note={note}>{note.title}</NotePreview>
          </span>
          <ArrowLeftOutline size={15} />
        </div>

        <div
          className="overflow-hidden prose prose-sm prose-xs text-gray-900 dark:text-near-white backlink-html-context py-1 empty:hidden"
          dangerouslySetInnerHTML={{__html: backlink.contextHtml}}
        />
      </a>
    )
  },
)

export {NoteIncomingBacklinkItem}
