import React from 'react'
import clsx from 'classnames'

export const Menu: React.FC = ({children}) => {
  return <div className="mt-10 flex flex-col space-y-10">{children}</div>
}

interface MenuSectionProps {
  title: string
}

export const MenuSection: React.FC<MenuSectionProps> = ({children, title}) => {
  return (
    <div className="px-3">
      <h3 className="px-6 pb-4 text-lg leading-6 font-medium text-gray-900 dark:text-near-white">
        {title}
      </h3>
      <ul className="space-y-2">{children}</ul>
    </div>
  )
}

interface MenuItemProps {
  selected?: boolean
  onClick?: any
}

export const MenuItem: React.FC<MenuItemProps> = ({children, selected, onClick}) => {
  return (
    <li
      onClick={onClick}
      className={clsx(
        'px-6 py-3 flex space-x-3 rounded-md',
        'text-gray-500 font-medium',
        'cursor-pointer transition duration-300',
        'hover:bg-gray-200',
        'dark:hover:bg-dark-black-900 dark:text-near-white dark:hover:text-purple-500',
        selected && 'bg-gray-200 dark:text-purple-500 dark:bg-dark-black-900',
      )}
    >
      {children}
    </li>
  )
}
