import {GeneratedGraphSerialization, GenerateGraphOptions} from './types'

export const generateGraph = (
  options: GenerateGraphOptions,
): Promise<GeneratedGraphSerialization> => {
  return new Promise((resolve) => {
    const worker = new Worker(new URL('./generate-graph-worker', import.meta.url))
    worker.addEventListener('message', (event: any) => {
      resolve(event.data)
      worker.terminate()
    })
    worker.postMessage(options)
  })
}
