import React from 'react'
import {Transition} from 'react-transition-group'
interface SearchTransitionProps {
  show: boolean
}

const SearchTransition: React.FC<SearchTransitionProps> = ({show, children}) => {
  const duration = 120

  const defaultStyle = {
    transition: `opacity ${duration}ms`,
    opacity: 0,
  }

  const transitionStyles = {
    entering: {opacity: 1},
    entered: {opacity: 1},
    exiting: {opacity: 0},
    exited: {opacity: 0},
    unmounted: {},
  }

  return (
    <Transition timeout={duration} in={show} mountOnEnter={true} unmountOnExit={true}>
      {(state) => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  )
}

export {SearchTransition}
