import React from 'react'
import {observer} from 'mobx-react-lite'
import {useNoteStore} from '../../../../models/store/context'
import {ArrowRightOutline} from 'heroicons-react'
import {NoteOutgoingBacklinkItemProps} from './types'
import {NotePreview} from '../../../../../components'

const NoteOutgoingBacklinkItem: React.FC<NoteOutgoingBacklinkItemProps> = observer(
  ({backlink}) => {
    const noteStore = useNoteStore()

    const note = noteStore.findById(backlink.toNoteId)

    if (!note) return null

    return (
      <div
        className="block cursor-pointer px-4 py-3 space-y-1"
        onClick={() => noteStore.setSelectedNoteId(note.id)}
      >
        <div className="flex items-center text-sm text-purple-550">
          <div className="flex-1 truncate">
            <NotePreview note={note}>{note.title}</NotePreview>
          </div>
          <ArrowRightOutline size={15} />
        </div>
      </div>
    )
  },
)

export {NoteOutgoingBacklinkItem}
