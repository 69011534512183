import React from 'react'
import {useNoteStore} from '../../../models/store/context'
import {PinnedNotes} from './pinned-notes'
import {SearchNotes} from './search-notes'
import {PencilAltOutline, ShareOutline, ViewListOutline} from 'heroicons-react'
import {AccountNav} from './account-nav/account-nav'
import {observer} from 'mobx-react-lite'
import {Menu, MenuItem} from './menu'
import {RegionDrag} from './region-drag'

export const NotesSidebar: React.FC = observer(() => {
  const noteStore = useNoteStore()
  const {view, selectedNote, todaysDailyNote} = noteStore

  return (
    <div className="flex-1 flex flex-col relative bg-coolgray-50 dark:bg-dark-black-800 overflow-auto space-y-5">
      <RegionDrag />

      <AccountNav />

      <SearchNotes />

      <Menu>
        <MenuItem
          selected={view === 'edit' && selectedNote?.daily}
          onClick={() => todaysDailyNote && noteStore.editNote(todaysDailyNote)}
        >
          <PencilAltOutline size={20} />
          <span>Daily notes</span>
        </MenuItem>

        <MenuItem
          selected={view === 'list' || (view === 'edit' && !selectedNote?.daily)}
          onClick={() => noteStore.setAndOpenSearchQuery('')}
        >
          <ViewListOutline size={20} />
          <span>All notes</span>
        </MenuItem>

        <MenuItem selected={view === 'brain'} onClick={() => noteStore.setView('brain')}>
          <ShareOutline size={20} className="transform rotate-90" />
          <span>Brain</span>
        </MenuItem>
      </Menu>

      <PinnedNotes />
    </div>
  )
})
