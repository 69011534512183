import {UserGroupOutline, UserCircleOutline, CreditCardOutline} from 'heroicons-react'
import {observer} from 'mobx-react-lite'
import React from 'react'
import {Graph} from '../../../models'
import {useGraphStore, usePreferenceStore} from '../../../models/store/context'
import {Back} from './back'
import {Menu, MenuSection, MenuItem} from './menu'

const PreferencesSidebar: React.FC = observer(() => {
  const preferences = usePreferenceStore()
  const {view, selectedGraph} = preferences
  const {ownedGraphs: graphs} = useGraphStore()

  const setSelectedGraph = (graph: Graph) => {
    preferences.setSelectedGraph(graph)
    preferences.setView('graph')
  }

  return (
    <div className="flex-1 flex flex-col bg-coolgray-50 dark:bg-dark-black-800 text-sidebar-grey-dark dark:bg-sidebar-darkmode-grey overflow-auto">
      <Back />

      <Menu>
        <MenuSection title="Personal settings">
          <MenuItem
            onClick={() => preferences.setView('profile')}
            selected={view === 'profile'}
          >
            <UserCircleOutline />
            <span>Profile</span>
          </MenuItem>

          <MenuItem
            onClick={() => preferences.setView('credentials')}
            selected={view === 'credentials'}
          >
            <UserGroupOutline />
            <span>Accounts</span>
          </MenuItem>

          <MenuItem
            onClick={() => preferences.setView('billing')}
            selected={view === 'billing'}
          >
            <CreditCardOutline />
            <span>Billing</span>
          </MenuItem>
        </MenuSection>

        <MenuSection title="Graphs">
          {graphs.map((graph) => (
            <MenuItem
              key={graph.id}
              onClick={() => setSelectedGraph(graph)}
              selected={view === 'graph' && selectedGraph?.id === graph.id}
            >
              <span>{graph.name}</span>
            </MenuItem>
          ))}
        </MenuSection>
      </Menu>
    </div>
  )
})

export {PreferencesSidebar}
