import {observer} from 'mobx-react-lite'
import React from 'react'
import {useUserStore} from '../../../models/store/context'
import {ThemePicker} from './theme-picker'
import {UserPhoto} from './user-photo'

export const PreferencesProfile: React.FC = observer(() => {
  const {currentUser} = useUserStore()

  if (!currentUser) {
    throw new Error('currentUser blank')
  }

  return (
    <div className="px-10 pt-10 flex flex-col">
      <h3 className="text-2xl leading-7 font-medium text-gray-900 dark:text-near-white">
        Profile settings
      </h3>

      <p className="mt-4 max-w-2xl text-lg leading-7 text-gray-500 dark:text-near-white">
        This information is taken from the service you used to authenticate.
      </p>

      <div className="mt-6 sm:mt-5">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t border-gray-100 dark:border-gray-900 sm:pt-5">
          <label className="block text-sm font-medium leading-5 text-gray-700 dark:text-near-white sm:mt-px sm:pt-2">
            Name
          </label>

          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="max-w-lg flex rounded-md shadow-sm">
              <div className="flex-1 px-3 py-2 block w-full min-w-0 sm:text-sm sm:leading-5 dark:bg-dark-black-800">
                {currentUser.name}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t border-gray-100 dark:border-gray-900 sm:pt-5">
          <label className="block text-sm font-medium leading-5 text-gray-700 dark:text-near-white sm:mt-px sm:pt-2">
            Email
          </label>

          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="max-w-lg flex rounded-md shadow-sm">
              <div className="flex-1 px-3 py-2 block w-full min-w-0 sm:text-sm sm:leading-5 dark:bg-dark-black-800">
                {currentUser.email}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t border-gray-100 dark:border-gray-900 sm:pt-5">
          <label className="block text-sm leading-5 font-medium text-gray-700 dark:text-near-white">
            Avatar
          </label>
          <div className="mt-2 sm:mt-0 sm:col-span-2">
            <div className="flex items-center">
              <UserPhoto user={currentUser} />
            </div>
          </div>
        </div>

        <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t border-gray-100 dark:border-gray-900 sm:pt-5">
          <label className="block text-sm leading-5 font-medium text-gray-700 dark:text-near-white">
            Theme
          </label>
          <div className="mt-2 sm:mt-0 sm:col-span-2">
            <ThemePicker />
          </div>
        </div>
      </div>
    </div>
  )
})
