import {BacklinkNodeData, Note} from 'app/models'
import {useNoteStore, useRootStore} from 'app/models/store/context'
import {FadeInTransition} from 'components'
import {observer} from 'mobx-react-lite'
import React, {useEffect, useRef, useState} from 'react'
import useDimensions from 'react-cool-dimensions'
import {Virtuoso, VirtuosoHandle} from 'react-virtuoso'
import {NoteEdit} from './note-edit'
import {TodaysNoteButton} from './todays-note-button'

interface Props {
  note: Note
}

interface Range {
  start: number
  end: number
}

export const NotesDailyEdit: React.FC<Props> = observer(({note}) => {
  const {ref, height, width} = useDimensions()
  const {orderedDailyNotes} = useNoteStore()
  const [availableBacklinks, setAvailableBacklinks] = useState<BacklinkNodeData[]>([])
  const rootStore = useRootStore()
  const virtuoso = useRef<VirtuosoHandle | null>(null)

  const notes = orderedDailyNotes.slice().reverse()
  const latestNoteIndex = notes.length - 1

  const [hasJustMounted, setJustMounted] = useState(true)

  const [currentRange, setCurrentRange] = useState<Range>({
    start: latestNoteIndex,
    end: latestNoteIndex,
  })

  const latestNoteVisible =
    latestNoteIndex >= currentRange.start && latestNoteIndex <= currentRange.end

  const scrollToIndex = (index: number) => {
    setTimeout(() => {
      virtuoso.current?.scrollToIndex({
        index,
        behavior: 'auto',
      })
    }, 0)
  }

  const onRangeChanged = ({
    startIndex: start,
    endIndex: end,
  }: {
    startIndex: number
    endIndex: number
  }) => {
    setCurrentRange({start, end})
  }

  useEffect(() => {
    setJustMounted(false)
  }, [])

  useEffect(() => {
    if (!note) {
      return
    }

    const index = notes.indexOf(note)

    if (hasJustMounted && index !== -1) {
      // Only scroll to active node if this screen has only just
      // become visible. (For the situations when you have
      // navigated here from some other screen.)
      scrollToIndex(index)
    }
  }, [note, hasJustMounted])

  useEffect(() => {
    setAvailableBacklinks(rootStore.availableBacklinks)
  }, [notes.length])

  return (
    <div className="flex-1 flex flex-col relative overflow-hidden">
      <FadeInTransition in={!latestNoteVisible} duration={300}>
        <div className="absolute top-3 right-3 z-10">
          <TodaysNoteButton onClick={() => scrollToIndex(latestNoteIndex)} />
        </div>
      </FadeInTransition>

      <div className="flex-1 flex-col overflow-hidden" ref={ref as any}>
        <Virtuoso
          ref={virtuoso}
          style={{width, height}}
          totalCount={notes.length}
          overscan={300}
          initialTopMostItemIndex={notes.length - 1}
          rangeChanged={onRangeChanged}
          itemContent={(index) => (
            <NoteEdit
              key={notes[index]?.id}
              availableBacklinks={availableBacklinks}
              isLastNote={index === notes.length - 1}
              notes={notes}
              note={notes[index]}
            />
          )}
        />
      </div>
    </div>
  )
})
