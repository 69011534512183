export const VERSION = '0.1.0'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AppProps {}

export type RootViewTypes = 'notes' | 'preferences'

export enum LoadingState {
  Auth = 'Auth',
  Gated = 'Gated',
  GraphSetup = 'GraphSetup',
  GraphEncryptionSetup = 'GraphEncryptionSetup',
  NoteStore = 'NoteStore',
  Pending = 'Pending',
}

export interface RouteState {
  rootView: 'notes' | 'preferences'
  noteView: 'list' | 'edit' | 'brain'
  preferenceView: 'profile' | 'credentials' | 'billing' | 'graph'
  currentGraphId: string | null
  noteSearchQuery: string | null
  selectedNoteId: string | null
  selectedNoteSlug: string | null
}

export declare type Route = [path: string, data?: RouteState]
