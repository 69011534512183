import {observer} from 'mobx-react-lite'
import React, {useEffect} from 'react'
import {useHotkeys} from 'react-hotkeys-hook'
import {usePopstate} from '../../plugins/use-popstate'
import {analytics} from '../../services/analytics'
import {
  useGraphStore,
  useNoteStore,
  usePreferenceStore,
  useRootStore,
} from '../models/store/context'
import {RouteState} from './types'

export const Router: React.FC = observer(() => {
  const rootStore = useRootStore()
  const graphStore = useGraphStore()
  const noteStore = useNoteStore()
  const preferenceStore = usePreferenceStore()

  const pushState = (path: string, state: RouteState) => {
    history.pushState(state, '', path)
  }

  const getRouteState = (): RouteState => {
    return {
      rootView: rootStore.view,
      noteView: noteStore.view,
      preferenceView: preferenceStore.view,
      currentGraphId: graphStore.currentGraph?.id || null,
      noteSearchQuery: noteStore.searchQuery || null,
      selectedNoteId: noteStore.selectedNote?.id || null,
      selectedNoteSlug: noteStore.selectedNote?.slug || null,
    }
  }

  const setRouteState = (state: RouteState) => {
    const {
      rootView,
      noteView,
      preferenceView,
      currentGraphId,
      noteSearchQuery,
      selectedNoteId,
    } = state

    rootStore.setView(rootView)
    noteStore.setView(noteView)
    preferenceStore.setView(preferenceView)
    graphStore.setCurrentGraphId(currentGraphId)
    noteStore.setSelectedNoteId(selectedNoteId)
    noteStore.setSearchQuery(noteSearchQuery)
  }

  const getRoutePath = (state: RouteState): string => {
    switch (state.rootView) {
      case 'preferences':
        return `/preferences/${state.preferenceView}`
      case 'notes':
        switch (state.noteView) {
          case 'list':
            if (state.currentGraphId) {
              const listQuery = state.noteSearchQuery ? `/${state.noteSearchQuery}` : ''
              return `/g/${state.currentGraphId}/list${listQuery}`
            }
            break
          case 'edit':
            if (state.selectedNoteSlug) {
              return `/g/${state.currentGraphId}/${state.selectedNoteSlug}`
            }
            break
          case 'brain':
            if (state.currentGraphId) {
              return `/g/${state.currentGraphId}/brain`
            }
            break
        }
    }

    return '/'
  }

  const state = getRouteState()

  useEffect(() => {
    const locationPath = location.pathname + location.hash
    const routePath = getRoutePath(state)

    if (locationPath != routePath) {
      pushState(routePath, state)
      analytics?.page()
    }
  }, [JSON.stringify(state)])

  usePopstate((event) => {
    setRouteState(event.state)
  })

  useHotkeys(
    'command+[, control+[',
    (event) => {
      event.preventDefault()
      history.back()
    },
    {filter: () => true},
  )

  useHotkeys(
    'command+], control+]',
    (event) => {
      event.preventDefault()
      history.forward()
    },
    {filter: () => true},
  )

  return null
})
