import {Transition} from '@headlessui/react'
import clsx from 'clsx'
import {observer} from 'mobx-react-lite'
import {useRouter} from 'next/router'
import React from 'react'
import {openWindow, PortalBody} from '../../../../../components'
import electron from '../../../../../services/electron'
import {useGraphStore, useRootStore, useUserStore} from '../../../../models/store/context'
import {DropdownItem} from './dropdown-item'
import {GraphOptions} from './graph-options'
import {AccountDropdownProps} from './types'

const AccountDropdown: React.FC<AccountDropdownProps> = observer(({show, position}) => {
  const router = useRouter()
  const rootStore = useRootStore()
  const {currentUser} = useUserStore()
  const {currentGraph, graphs} = useGraphStore()

  const otherGraphs = graphs.filter((g) => g != currentGraph)

  const signOut = async () => {
    if (typeof electron?.signOut === 'function') {
      electron.signOut()
    } else {
      router.push('/sign-out')
    }
  }

  const {left, top} = position

  return (
    <PortalBody>
      <div style={{left: left, top: top}} className="z-10 origin-top absolute">
        <Transition
          show={show}
          enter="transition ease-out duration-300"
          enterFrom="transform opacity-0 "
          enterTo="transform opacity-100 "
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 "
          leaveTo="transform opacity-0 "
        >
          <div
            style={{margin: '-0.75em'}}
            className={clsx([
              'max-w-64 min-w-64',
              'rounded-md shadow-lg bg-white',
              'dark:bg-gray-700',
              'ring-1 ring-black ring-opacity-5',
              'divide-y divide-gray-200 dark:divide-gray-600',
            ])}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <div className="flex items-center cursor-pointer space-x-3 px-3 py-3">
              <div className="rounded-md overflow-hidden h-8 w-8 bg-gray-200 dark:bg-black">
                <div
                  className="bg-contain bg-center bg-no-repeat inline-block h-full w-full"
                  style={{backgroundImage: `url(${currentUser?.photoUrl})`}}
                />
              </div>

              <div className="text-gray-900 dark:text-near-white text-base font-medium">
                {currentGraph?.name}
              </div>
            </div>

            <GraphOptions graphs={otherGraphs} />

            <div className="py-1">
              <DropdownItem onClick={() => rootStore.setView('preferences')}>
                Settings
              </DropdownItem>
              {!electron && (
                <DropdownItem onClick={() => openWindow('https://reflect.app/download')}>
                  Download app
                </DropdownItem>
              )}
              <DropdownItem onClick={() => openWindow('mailto:support@reflect.app')}>
                Support
              </DropdownItem>
            </div>

            <div className="py-1">
              <DropdownItem onClick={signOut}>Sign out</DropdownItem>
            </div>
          </div>
        </Transition>
      </div>
    </PortalBody>
  )
})

export {AccountDropdown}
