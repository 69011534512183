import React from 'react'
import {Hand} from 'heroicons-react'
import {observer} from 'mobx-react-lite'
import {FullModal} from '../../../components'

const Gated: React.FC = observer(() => {
  return (
    <FullModal>
      <div className="max-w-md">
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-purple-100 text-gray-900">
          <Hand />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-near-white">
            Invite only access
          </h3>
          <div className="mt-5 space-y-5">
            <p className="text-sm leading-5 text-gray-500 dark:text-gray-200">
              Sorry, you don&apos;t have access to Reflect yet. We are working hard to
              open up to everyone but in the meantime please bear with us. We&apos;ll send
              you an email when your account is ready.
            </p>

            <div className="space-y-1">
              <p className="text-xs text-gray-500 dark:text-gray-400">
                ps. If you really can&apos;t wait,{' '}
                <a
                  target="_blank"
                  className="text-teal-500"
                  href="https://twitter.com/maccaw"
                >
                  bug Alex
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </FullModal>
  )
})

export {Gated}
