import React from 'react'
import {observer} from 'mobx-react-lite'
import {Note} from '../../../models/note/note'

interface NoteShowProps {
  note: Note
}

const NoteShow: React.FC<NoteShowProps> = observer(({note}) => {
  return (
    <div className="flex-1 flex flex-col overflow-hidden h-full w-full">
      <div className="max-w-screen-md flex-1 flex flex-col overflow-auto">
        <div
          className="prose prose-sm text-black dark:prose-dark dark:text-near-white
          flex-grow flex-shrink-0 max-w-none select-text py-10 px-10"
          dangerouslySetInnerHTML={{__html: note.contentHtml}}
        />
      </div>
    </div>
  )
})

export {NoteShow}
