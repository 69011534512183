import React from 'react'
import {truncate} from 'lodash'
import {Link} from '../../../../../components'
import {
  LogoTwitter,
  LogoFacebook,
  LogoGithub,
  LogoLinkedin,
} from '@styled-icons/ionicons-solid'

export const Company = ({company}: {company: any}) => {
  return (
    <div className="flex space-x-4">
      <div className="rounded-md overflow-hidden h-16 w-16 bg-coolgray-50 dark:bg-black">
        {company.logo && (
          <div
            className="bg-contain bg-center bg-no-repeat inline-block h-full w-full"
            style={{backgroundImage: `url(${company.logo})`}}
          />
        )}
      </div>

      <div className="flex-1 flex flex-col space-y-1">
        <h3 className="text-purple-550 text-lg">
          <Link href={`http://${company.domain}`}>{company.name}</Link>
        </h3>

        {company.description && (
          <div className="text-sm" title={company.description}>
            {truncate(company.description, {length: 100})}
          </div>
        )}

        <div className="flex space-x-3">
          {company.twitter?.handle && (
            <Link
              href={`https://twitter.com/${company.twitter.handle}`}
              className="text-gray-400 hover:text-purple-550 transition-all duration-300 ease-in-out"
            >
              <LogoTwitter size={18} />
            </Link>
          )}

          {company.github?.handle && (
            <Link
              href={`https://github.com/${company.github.handle}`}
              className="text-gray-400 hover:text-purple-550 transition-all duration-300 ease-in-out"
            >
              <LogoGithub size={18} />
            </Link>
          )}

          {company.facebook?.handle && (
            <Link
              href={`https://facebook.com/${company.facebook.handle}`}
              className="text-gray-400 hover:text-purple-550 transition-all duration-300 ease-in-out"
            >
              <LogoFacebook size={18} />
            </Link>
          )}

          {company.linkedin?.handle && (
            <Link
              href={`https://linkedin.com/${company.linkedin.handle}`}
              className="text-gray-400 hover:text-purple-550 transition-all duration-300 ease-in-out"
            >
              <LogoLinkedin size={18} />
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}
