import React from 'react'
import {ArrowLeft} from 'heroicons-react'
import clsx from 'clsx'
import {useRootStore} from '../../../models/store/context'

export const Back: React.FC = () => {
  const rootStore = useRootStore()

  return (
    <a
      className={clsx([
        'mt-16 ml-3 px-4 py-2',
        'border border-transparent rounded-md',
        'text-sm leading-4 font-medium text-gray-400 dark:text-near-white',
        'dark:bg-transparent hover:text-purple-600 dark:hover:text-purple-600',
        'focus:outline-none focus:border-purple-300 focus:shadow-outline-purple',
        'transition ease-in-out duration-500 cursor-pointer',
      ])}
      onClick={() => rootStore.setDefaultView()}
    >
      <ArrowLeft size={25} />
    </a>
  )
}
