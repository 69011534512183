import React, {MouseEventHandler, useRef} from 'react'
import {Note} from '../../../models/note/note'
import clsx from 'classnames'
import {observer} from 'mobx-react-lite'
import {ContextMenu} from '../../../../components/context-menu/context-menu'

interface NoteListItemProps {
  note: Note
  selected: boolean
  onClick?: MouseEventHandler
}

const NoteListItem: React.FC<NoteListItemProps> = observer(
  ({note, selected, onClick}) => {
    const elementRef = useRef(null)

    return (
      <div
        ref={elementRef}
        onClick={onClick}
        className={clsx(
          'flex flex-col px-4 py-2 text-sm leading-5 rounded-md bg-coolgray-50 hover:bg-gray-200 dark:bg-dark-black-800 dark:hover:bg-dark-black-900 focus:outline-none transition ease-in-out duration-300 cursor-pointer',
          {' bg-gray-200 dark:bg-dark-black-900': selected},
        )}
      >
        <div className="flex items-baseline space-x-1 text-gray-800 dark:text-near-white">
          <div className="truncate font-medium flex-1 text-base" title={note.title}>
            {note.title}
          </div>
        </div>

        <div className="flex space-x-2 text-gray-500 dark:text-near-white mt-1">
          <div className="flex-none text-sm" title={note.updatedAt + ''}>
            {note.updatedAtFormatted}
          </div>
          <div className="truncate text-sm flex-1 text-gray-400" title={note.snippet}>
            {note.snippet}
          </div>
        </div>

        <ContextMenu parentRef={elementRef} enabled={!note.daily}>
          <div className="rounded-md shadow-lg overflow-hidden w-32">
            <div
              onClick={() => note.togglePinned()}
              className="px-3 py-2 text-sm leading-5 cursor-pointer bg-white dark:bg-dark-black-800 text-gray-700 dark:text-near-white hover:bg-gray-100 hover:text-gray-900 transition ease-in-out duration-150"
            >
              {note.pinned ? 'Unpin note' : 'Pin note'}
            </div>

            <div
              onClick={(event) => {
                if (event.metaKey || confirm('Are you sure?')) note.deleteOrDestroy()
              }}
              className="px-3 py-2 text-sm leading-5 cursor-pointer bg-white dark:bg-dark-black-800 text-gray-700 dark:text-near-white hover:bg-gray-100 hover:text-gray-900 transition ease-in-out duration-150"
            >
              Delete note
            </div>
          </div>
        </ContextMenu>
      </div>
    )
  },
)

export {NoteListItem}
