import React, {useEffect, useRef, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {useHotkeys} from 'react-hotkeys-hook'
import {useMessage} from '../../../../plugins/use-message'
import {useNoteStore, useRootStore} from '../../../models/store/context'
import {SearchResultItem} from './search-result-item'
import {SearchTransition} from './search-transition'
import {SearchCreate} from './search-create'
import {
  getNextIndex,
  getNoteResults,
  getPreviousIndex,
  getSearchType,
  getTagResults,
} from './utils'
import {Search} from 'heroicons-react'
import {Virtuoso, VirtuosoHandle} from 'react-virtuoso'
import {useNotesCache} from './use-notes-cache'
import {SearchResult} from './types'
import {PortalBody} from '../../../../components'

const NotesSearch: React.FC = observer(() => {
  const noteStore = useNoteStore()
  const {assertCurrentGraph: currentGraph} = useRootStore()
  const {quickSearchQuery} = noteStore
  const virtuoso = useRef<VirtuosoHandle | null>(null)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const isOpen = quickSearchQuery !== null
  const searchType = getSearchType(quickSearchQuery)

  // Warm up the notes cache
  useNotesCache(isOpen)

  let results: SearchResult[] = []

  switch (searchType) {
    case 'tags':
      results = getTagResults(quickSearchQuery!, currentGraph)
      break
    case 'notes':
      results = getNoteResults(quickSearchQuery!, noteStore)
      break
  }

  // Number of results + <SearchCreate />
  const resultsLength = results.length

  useEffect(() => {
    virtuoso.current?.scrollToIndex({
      index: selectedIndex,
      behavior: 'smooth',
    })
  }, [selectedIndex])

  const close = () => noteStore.setQuickSearchQuery(null)

  const selectResult = (result: SearchResult) => {
    if (result.note) {
      noteStore.setAndOpenSelectedNote(result.note)
    } else if (result.tag) {
      noteStore.setAndOpenSearchQuery(result.tag)
    }

    close()
  }

  const createNoteFromSearch = () => {
    const note = noteStore.createNote({subject: quickSearchQuery})
    noteStore.setAndOpenSelectedNote(note)
    close()
  }

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault()

      return setSelectedIndex(getNextIndex(selectedIndex, resultsLength))
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault()
      return setSelectedIndex(getPreviousIndex(selectedIndex, resultsLength))
    }

    if (event.key === 'Escape') {
      event.preventDefault()
      return close()
    }

    if (['Tab', 'Enter'].includes(event.key)) {
      event.preventDefault()
      const result = results[selectedIndex]

      if (result) {
        selectResult(result)
      } else if (quickSearchQuery) {
        createNoteFromSearch()
      }

      close()
      return false
    }
  }

  useMessage((event) => {
    switch (event?.data?.command) {
      case 'open-search':
        noteStore.openQuickSearch()
        break
    }
  })

  useHotkeys('escape', () => close())

  // Chrome PWA API
  useHotkeys(
    ['command+p', 'command+t', 'control+p', 'control+t'].join(', '),
    (event) => {
      event.preventDefault()
      noteStore.openQuickSearch()
    },
    {filter: () => true},
  )

  return (
    <PortalBody>
      <SearchTransition show={isOpen}>
        <div className="fixed z-10 inset-0 pt-4 px-4 flex flex-col items-center">
          <div className="fixed inset-0 transition-opacity">
            <div
              className="absolute inset-0 bg-white opacity-75 dark:bg-black"
              onClick={close}
            />
          </div>

          <div
            className={[
              'flex flex-col rounded-xl shadow-xl my-16 max-w-lg w-full',
              'bg-pitch-gray-900 text-white overflow-hidden',
              'transform transition-all',
            ].join(' ')}
            role="dialog"
          >
            <div className="flex px-5 py-4 flex-none">
              <div className="flex-none mr-3 flex justify-center items-center">
                <Search size={20} />
              </div>

              <input
                autoFocus
                type="text"
                className="text-white bg-transparent outline-none flex-1 border-none m-0 p-0 focus:ring-0"
                value={quickSearchQuery || ''}
                onKeyDown={onKeyDown}
                onChange={(e) => {
                  setSelectedIndex(0)
                  noteStore.setQuickSearchQuery(e.target.value)
                }}
              />
            </div>

            <div className="flex-1 flex flex-col">
              <Virtuoso
                ref={virtuoso}
                style={{
                  width: '100%',
                  height: Math.min(64 * resultsLength, 450),
                }}
                totalCount={resultsLength}
                itemContent={(index) => (
                  <SearchResultItem
                    key={results[index]?.id}
                    result={results[index]}
                    selected={selectedIndex === index}
                    onClick={() => {
                      selectResult(results[index])
                      close()
                    }}
                  />
                )}
              />

              {searchType === 'notes' && (
                <>
                  <SearchCreate
                    query={quickSearchQuery!}
                    selected={selectedIndex === resultsLength}
                    onClick={createNoteFromSearch}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </SearchTransition>
    </PortalBody>
  )
})

export {NotesSearch}
