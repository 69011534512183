import React, {ReactElement} from 'react'

interface Props {
  keys: string[]
}

interface KeyMap {
  [key: string]: string | ReactElement
}

const KEY_MAP: KeyMap = {
  apple: <span>&#63743;</span>,
  command: <span>&#8984;</span>,
  control: <span>&#8963;</span>,
  option: <span>&#8997;</span>,
  shift: <span>&#8679;</span>,
  return: <span>&#8617;</span>,
  delete: <span>&#9003;</span>,
  escape: <span>&#9099;</span>,
  rightArrow: <span>&rarr;</span>,
  leftArrow: <span>&larr;</span>,
  upArrow: <span>&uarr;</span>,
  downArrow: <span>&darr;</span>,
  tab: <span>&#8677;</span>,
  shiftTab: <span>&#8676;</span>,
}

export const Shortcut: React.FC<Props> = ({keys: shortcutKeys}) => (
  <div className="flex space-x-1">
    {shortcutKeys.map((shortcutKey, i) => (
      <code
        className="block leading-5 px-2 py-0 bg-gray-100 dark:bg-dark-black-800 rounded-md"
        key={i}
      >
        {KEY_MAP[shortcutKey] || shortcutKey}
      </code>
    ))}
  </div>
)
