import * as localForage from 'localforage'
import {debounce} from 'lodash'
import {applySnapshot, getSnapshot, onSnapshot} from 'mobx-keystone'
export interface IArgs {
  (name: string, store: any, options?: IOptions): Promise<void>
}
export interface IOptions {
  storage?: LocalForage
  jsonify?: boolean
  readonly whitelist?: Array<string>
  readonly blacklist?: Array<string>
  migration?: number
}
type StrToAnyMap = {[key: string]: any}

export const persist: IArgs = async (name, store, options = {}) => {
  const {
    storage = localForage,
    jsonify = true,
    whitelist,
    blacklist,
    migration = 1,
  } = options

  const whitelistDict = arrToDict(whitelist)
  const blacklistDict = arrToDict(blacklist)
  const storageKey = `${name}-${migration}`

  onSnapshot(
    store,
    debounce(async (_snapshot: StrToAnyMap) => {
      const snapshot = {..._snapshot}

      Object.keys(snapshot).forEach((key) => {
        if (whitelist && !whitelistDict[key]) {
          delete snapshot[key]
        }
        if (blacklist && blacklistDict[key]) {
          delete snapshot[key]
        }
      })

      const data = !jsonify ? snapshot : JSON.stringify(snapshot)

      await storage.setItem(storageKey, data)
    }, 2000),
  )

  const storedItem = await storage.getItem(storageKey)

  const storedSnapshot = isString(storedItem) ? JSON.parse(storedItem) : storedItem

  if (!storedSnapshot) return

  const mergedSnapshot = {
    ...getSnapshot(store),
    ...storedSnapshot,
    $modelId: store.$modelId,
  }

  applySnapshot(store, mergedSnapshot)
}

type StrToBoolMap = {[key: string]: boolean}

function arrToDict(arr?: Array<string>): StrToBoolMap {
  if (!arr) {
    return {}
  }
  return arr.reduce((dict: StrToBoolMap, elem) => {
    dict[elem] = true
    return dict
  }, {})
}

function isString(value: any): value is string {
  return typeof value === 'string'
}

export default persist
