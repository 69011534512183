import clsx from 'clsx'
import React, {MouseEventHandler} from 'react'
import {Note} from '../../../models'

interface NotesListItem {
  note: Note
  onClick?: MouseEventHandler
}

export const NotesListItem: React.FC<NotesListItem> = ({note, onClick}) => {
  return (
    <div
      onClick={onClick}
      className={clsx([
        'grid grid-cols-7 gap-4 px-6 lg:px-7 py-3',
        'text-sm font-medium text-gray-900',
        'border-b border-gray-100 dark:border-gray-900 cursor-pointer',
        'hover:bg-coolgray-50 dark:hover:bg-gray-900',
        'transition-colors duration-75',
      ])}
    >
      <div className="lg:pl-2 col-span-2 whitespace-nowrap truncate text-purple-500">
        {note.subject}
      </div>

      <div className="col-span-3 whitespace-nowrap">
        <div className="text-sm text-gray-500 font-normal truncate">{note.snippet}</div>
      </div>

      <div className="col-span-1 whitespace-nowrap">
        <div className="text-sm text-gray-500 font-normal truncate">
          {note.tags.map((tag) => `#${tag}`).join(', ')}
        </div>
      </div>

      <div className="">
        <div className="text-sm text-gray-500 font-normal">{note.updatedAtFormatted}</div>
      </div>
    </div>
  )
}
