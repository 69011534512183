import React from 'react'
import {observer} from 'mobx-react-lite'
import {NoteToolbarProps} from './note-toolbar.props'
import {WhiteButton} from '../../../../../components'
import {TrashOutline, LocationMarkerOutline, ReceiptRefundOutline} from 'heroicons-react'
import {useNotificationStore} from '../../../../models/store/context'

const NoteToolbar: React.FC<NoteToolbarProps> = observer(({note}) => {
  const notifications = useNotificationStore()

  const onDelete = () => {
    if (note.deletedAt) {
      if (confirm('Are you sure? This will delete your note permanently.')) {
        note.destroy()
        notifications.create({
          subject: 'Destroyed',
          description: 'Note permanently destroyed',
          closeIn: 1000 * 3,
        })
      }
    } else {
      note.delete()
      notifications.create({
        subject: 'Trashed',
        description: 'Note sent to trash',
        closeIn: 1000 * 3,
      })
    }
  }

  return (
    <div className="space-y-3 z-10">
      <h3 className="text-sm font-semibold uppercase tracking-widest leading-5 text-gray-400">
        {note.daily ? note.subject : 'Note actions'}
      </h3>

      <div className="flex flex-wrap justify-start -m-1">
        <div className="m-1">
          <WhiteButton onClick={() => note.togglePinned()}>
            {note.pinned ? (
              <>
                <LocationMarkerOutline size={15} />
                <span>Unpin</span>
              </>
            ) : (
              <>
                <LocationMarkerOutline size={15} />
                <span>Pin</span>
              </>
            )}
          </WhiteButton>
        </div>

        <div className="m-1">
          <WhiteButton onClick={() => onDelete()}>
            <TrashOutline size={15} />
            <span>{note.deletedAt ? 'Destroy' : 'Trash'}</span>
          </WhiteButton>
        </div>

        {note.deletedAt && (
          <>
            <div className="m-1">
              <WhiteButton onClick={() => note.restore()}>
                <ReceiptRefundOutline size={15} />
                <span>Restore</span>
              </WhiteButton>
            </div>
          </>
        )}
      </div>
    </div>
  )
})

export {NoteToolbar}
