import {autorun} from 'mobx'
import {useEffect} from 'react'
import {useNoteStore} from '../../../models/store/context'

export const useNotesCache = (warmup: boolean) => {
  const {notes} = useNoteStore()

  useEffect(() => {
    let cleanup: () => void | undefined

    if (warmup) {
      setTimeout(() => {
        cleanup = autorun(() => {
          notes.forEach((note) => {
            note.contentTags
            note.matchTexts
          })
        })
      })
    }

    return () => cleanup?.()
  }, [warmup])
}
