import clsx from 'clsx'
import {Plus} from 'heroicons-react'
import React, {MouseEventHandler} from 'react'

interface NewButtonProps {
  onClick: MouseEventHandler
}

export const NewButton: React.FC<NewButtonProps> = ({onClick}) => {
  return (
    <button
      onClick={onClick}
      type="button"
      style={{width: 35, height: 35}}
      className={clsx([
        'inline-flex items-center justify-center text-center shadow-sm',
        'bg-white dark:bg-transparent',
        'rounded-full border border-transparent dark:border-gray-600',
        'text-sm leading-4 font-medium text-gray-700',
        'dark:text-near-white dark:hover:border-purple-500 dark:hover:text-purple-500 hover:text-purple-500',
        'focus:outline-none focus:border-purple-300 focus:shadow-outline-purple active:bg-coolgray-50',
        'transition ease-in-out duration-300',
        'cursor-pointer',
      ])}
    >
      <Plus size={20} />
    </button>
  )
}
