import React from 'react'
import {observer} from 'mobx-react-lite'
import {useNoteStore} from '../../../../models/store/context'
import {ArrowRightOutline} from 'heroicons-react'
import {NoteTagItemProps} from './types'

const NoteTagItem: React.FC<NoteTagItemProps> = observer(({tag}) => {
  const noteStore = useNoteStore()

  return (
    <a
      className="block cursor-pointer px-4 py-3 space-y-1"
      onClick={() => noteStore.setAndOpenSearchQuery(`#${tag}`)}
    >
      <div className="flex items-center text-sm text-purple-550">
        <div className="flex-1 truncate">#{tag}</div>
        <ArrowRightOutline size={15} />
      </div>
    </a>
  )
})

export {NoteTagItem}
