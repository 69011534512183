import React from 'react'
import {ButtonGroup, ButtonGroupOption} from '../../../../components'
import {useNoteStore} from '../../../models/store/context'
import {TRASH_QUERY} from './types'

export const TagsToggle: React.FC = () => {
  const noteStore = useNoteStore()

  return (
    <ButtonGroup>
      <ButtonGroupOption
        selected={!noteStore.searchQuery}
        position="left"
        onClick={() => noteStore.setSearchQuery('')}
      >
        All
      </ButtonGroupOption>

      <ButtonGroupOption
        selected={noteStore.searchQuery === '#book'}
        onClick={() => noteStore.setSearchQuery('#book')}
      >
        Books
      </ButtonGroupOption>

      <ButtonGroupOption
        selected={noteStore.searchQuery === '#link'}
        onClick={() => noteStore.setSearchQuery('#link')}
      >
        Links
      </ButtonGroupOption>

      <ButtonGroupOption
        selected={noteStore.searchQuery === '#person'}
        onClick={() => noteStore.setSearchQuery('#person')}
      >
        People
      </ButtonGroupOption>

      <ButtonGroupOption
        selected={noteStore.searchQuery === TRASH_QUERY}
        position="right"
        onClick={() => noteStore.setSearchQuery(TRASH_QUERY)}
      >
        Trash
      </ButtonGroupOption>
    </ButtonGroup>
  )
}
