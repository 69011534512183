import dynamic from 'next/dynamic'

export const PreferencesProfile = dynamic<unknown>(() =>
  import('./preferences-profile/preferences-profile').then(
    (lib) => lib.PreferencesProfile,
  ),
)

export const PreferencesContacts = dynamic<unknown>(() =>
  import('./preferences-credentials/preferences-credentials').then(
    (lib) => lib.PreferencesCredentials,
  ),
)

export const PreferencesGraph = dynamic<unknown>(() =>
  import('./preferences-graph/preferences-graph').then((lib) => lib.PreferencesGraph),
)

export const PreferencesBilling = dynamic<unknown>(() =>
  import('./preferences-billing/preferences-billing').then(
    (lib) => lib.PreferencesBilling,
  ),
)
